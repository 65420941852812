import styles from "./AddReview.module.scss";
import { useState, useContext, useEffect } from "react";
import ReviewForm from "../../components/add-review/ReviewForm";
import { AuthenticatedUserContext } from "./../../providers/AuthenticatedUserProvider";
import { db } from "./../../firebase";
import {
  addDoc,
  collection,
  updateDoc,
  doc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import ModalBase from "./../../components/common/modal/Modal";
import { Tooltip as ReactTooltip } from "react-tooltip";

function AddReview(props) {
  document.title = "Trainer Rater | Add Review";

  const navigate = useNavigate();
  const { userInfo, setUserInfo } = useContext(AuthenticatedUserContext);

  const [multiSelected, setMultiSelected] = useState([]);

  const [location, setLocation] = useState("");

  const [latlng, setLatLng] = useState({});

  const [tooManyModal, setTooManyModal] = useState(false);

  const [addClass, setAddClass] = useState(false);
  const [addTrainer, setAddTrainer] = useState(false);

  const [classLoading, setClassLoading] = useState(true);
  const [trainerLoading, setTrainerLoading] = useState(true);
  const [error, setError] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false)

  useEffect(() => {
    if (!classLoading && addClass) {
      if (!addTrainer) {
        finalSubmit();
      } else if (addTrainer && !trainerLoading) {
        finalSubmit();
      }
    }
    if (!trainerLoading && addTrainer && !addClass) {
      finalSubmit();
    }
  }, [trainerLoading, classLoading]);

  const toggleOption = (tag) => {
    setMultiSelected((prevSelected) => {
      // if it's in, remove
      const newArray = [...prevSelected];
      if (newArray.includes(tag)) {
        return newArray.filter((item) => item != tag);
        // else, add
      } else {
        newArray.push(tag);
        return newArray;
      }
    });
  };

  const [values, setValues] = useState({
    comments: "",
    type: "",
    rating: "",
    trainer: "",
    classname: "",
    "go-again": null,
  });

  const [newTrainer, setNewTrainer] = useState("");
  const [trainerLocation, setTrainerLocation] = useState("");
  const [trainerLatlng, setTrainerLatLng] = useState({});

  function handleChange(key, value) {
    if (key == "classname" && value?.gym != "") {
      setValues((values) => {
        return {
          ...values,
          type: value.gym,
          [key]: value,
        };
      });
    } else {
      setValues((values) => {
        return {
          ...values,
          [key]: value,
        };
      });
    }
  }

  async function submitReview(userReviews) {
    const timeArray = userReviews
      .filter((review) => review.data.trainer === values.trainer.id)
      .sort((a, b) => b.data.date - a.data.date)
      .map((review) => review.data.date);
    let timeDiff;
    if (timeArray.length >= 3) {
      const date2 = timeArray[4];
      timeDiff = new Date(date2).getTime() - new Date().getTime();
    } else {
      timeDiff = new Date().getTime() - new Date().getTime();
    }

    // console.log(timeDiff / (1000 * 3600 * 24));

    if (timeDiff / (1000 * 3600 * 24) > -31) {
      setSubmitDisabled(true)
      if (addClass) {
        const q = query(
          collection(db, "classes"),
          where("class", "==", values["add-class"])
        );
        let docsCount = 0;
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          docsCount++;
        });
        if (docsCount > 0) {
          setError(
            "There is already at class at this gym with the same name. Please use the dropdown to find it."
          );
        } else {
          await addDoc(collection(db, "classes"), {
            createdBy: userInfo.email,
            class: values["add-class"] || "",
            gym: values.type || "",
            date: new Date().valueOf(),
            rating: [values.rating || 0],
            takeAgain: [values["go-again"] || false],
          }).then((data) => {
            setValues((values) => {
              return {
                ...values,
                classId: data.id,
              };
            });
            setClassLoading(false);
          });
        }
      }
      if (addTrainer) {
        await addDoc(collection(db, "trainers"), {
          _geoloc: { lat: trainerLatlng.lat, lng: trainerLatlng.lng } || {},
          name: newTrainer,
          city: trainerLocation || "",
          createdBy: userInfo.email,
          description: "",
          date: new Date().valueOf(),
          rating: [values.rating || 0],
          takeAgain: [values["go-again"] || false],
          type: [values.type || ""],
          latlng: trainerLatlng || {},
        }).then((data) => {
          setValues((values) => {
            return {
              ...values,
              trainerId: data.id,
            };
          });
          setTrainerLoading(false);
        });
      }

      if (!addClass && !addTrainer) {
        await finalSubmit();
      }
    } else {
      setTooManyModal(true);
    }
  }

  async function finalSubmit() {
    await addDoc(collection(db, "reviews"), {
      user: userInfo.email,
      comments: values.comments || "",
      date: new Date().valueOf(),
      rating: values.rating || 0,
      takeAgain: values["go-again"] || false,
      trainer: values?.trainerId || values.trainer.objectID || "",
      gym: values.type || "",
      tags: multiSelected || [],
      classname: values.classname.class || values["add-class"] || "",
      location: location || "",
      latlng: latlng || {},
      likes: [],
    });

    if (!addTrainer) {
      const ref = doc(
        db,
        "trainers",
        values.trainerId != undefined
          ? values.trainerId
          : values.trainer.objectID
      );

      await updateDoc(ref, {
        rating: [...values.trainer.rating, values.rating],
        takeAgain: [...values.trainer.takeAgain, values["go-again"]],
        type: [...values.trainer.type, values.type],
      });
    }

    if (!addClass) {
      const ref2 = doc(
        db,
        "classes",
        values.classId != undefined ? values.classId : values.classname.objectID
      );

      await updateDoc(ref2, {
        rating: [...values.classname.rating, values.rating],
        takeAgain: [...values.classname.takeAgain, values["go-again"]],
      });
    }

    navigate(
      "../../trainers/" + (values?.trainerId || values.trainer.objectID)
    );
  }

  return (
    <div>
      {userInfo == null && (
        <div className={styles["user-null"]}>
          <div className={styles["user-null-content"]}>
            <h2>User Not Signed In</h2>
            <p>In order to complete review you must be signed in.</p>
            <span
              className={styles.modalLogin}
              onClick={() => {
                props.setIsOpen(true);
              }}
            >
              Sign In/Sign Up
            </span>
          </div>
        </div>
      )}

      {tooManyModal && (
        <ModalBase isOpen={tooManyModal} close={() => setTooManyModal(false)}>
          <h1 className={styles.modalH1}>Review Limit Reached</h1>
          <p className={styles.modalP}>
            You have reviewed <b>{values.trainer.data.name}</b> too many times{" "}
            <b>(5)</b> in the last 30 days. To prevent an oversaturation of
            reviews, reviews are limited to <b>5</b> per user for each trainer,
            every <b>30</b> days.
          </p>
        </ModalBase>
      )}

      <div>
        <center>
          <h1 className={styles.title}>Add A Review</h1>
        </center>
        <ReviewForm
          values={values}
          handleChange={handleChange}
          submitReview={submitReview}
          toggleOption={toggleOption}
          multiSelected={multiSelected}
          setLocation={setLocation}
          setLatLng={setLatLng}
          addClass={addClass}
          setAddClass={setAddClass}
          addTrainer={addTrainer}
          setAddTrainer={setAddTrainer}
          newTrainer={newTrainer}
          setNewTrainer={setNewTrainer}
          trainerLocation={trainerLocation}
          setTrainerLocation={setTrainerLocation}
          trainerLatlng={trainerLatlng}
          setTrainerLatLng={setTrainerLatLng}
          error={error}
          submitDisabled={submitDisabled}
        />
      </div>
    </div>
  );
}

export default AddReview;
