import BurgerMenu from "./../burger-menu/BurgerMenu";
import styles from "./Header.module.scss";
import { useEffect, useState } from "react";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faUser } from "@fortawesome/free-solid-svg-icons";

import GoogleAutocomplete from "./../../components/google-autocomplete/GoogleAutocomplete";
import Dropdown from "./../../components/common/dropdown/Dropdown";

import { Autocomplete } from "../../components/algolia/Autocomplete";
import { ClassesAutocomplete } from "../../components/algolia/ClassesAutocomplete";
import ReactGA from "react-ga4";

function Header(props) {
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TRACKING_ID);

  let location = useLocation();
  const navigate = useNavigate();
  // const [location, setLocation] = useState("");

  const [latlng, setLatLng] = useState({});

  const [mileRadius, setMileRadius] = useState(25);

  const [searchType, setSearchType] = useState("Trainer");

  let activeStyle = {
    textDecoration: "underline",
  };

  const StyledMenu = styled((props) => <Menu elevation={0} {...props} />)(
    () => ({
      "& .MuiPaper-root": {
        borderRadius: 6,
        minWidth: 180,
        backgroundColor: "#EDF2F4",

        boxShadow:
          "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
          padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
          "& .MuiSvgIcon-root": {
            fontSize: 18,
          },
          "&:active": {},
        },
      },
    })
  );

  // const [activeUser, setActiveUser] = useState(props.user);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleSearch(searchVal) {
    navigate(
      "./search?" +
        searchType.toLowerCase() +
        "=" +
        searchVal +
        "&location=" +
        location
    );
  }

  function handleClassSearch(searchVal) {
    navigate("./search?" + searchType.toLowerCase() + "=" + searchVal);
  }

  return (
    <header
      className={`${styles["header-main"]} ${
        location.pathname === "/" ? "" : styles.white
      }`}
    >
      <div className={styles["header-second-level-desktop"]}>
        <Link className={styles.logo} to="/">
          <img
            alt="Company logo"
            src={process.env.PUBLIC_URL + "/alt-logo.png"}
          />
        </Link>
        <div className={styles["nav-menu"]}>
          {location.pathname !== "/" && location.pathname !== "/search" && (
            <div className={styles.search}>
              <div className={styles.filters}>
                <div className={styles["filter-div"]}>
                  <span>Search For: </span>
                  <Dropdown
                    selected={searchType}
                    setIsSelected={setSearchType}
                    handleChange={() => {}}
                    options={["Trainer", "Class"]}
                    page={"home"}
                  />
                </div>
                {searchType == "Trainer" && (
                  <div className={styles["filter-div"]}>
                    <span>Mile Radius: </span>
                    <Dropdown
                      selected={mileRadius}
                      setIsSelected={setMileRadius}
                      handleChange={() => {}}
                      options={[5, 10, 25, 50]}
                      page={"home"}
                    />
                  </div>
                )}
              </div>

              <div
                className={
                  styles.inputs +
                  (searchType == "Class" ? " " + styles.classes : "")
                }
              >
                {searchType == "Trainer" ? (
                  <Autocomplete
                    latlng={latlng}
                    mileRadius={mileRadius}
                    type={searchType}
                    handleSearch={handleSearch}
                    page={"header"}
                  />
                ) : (
                  <ClassesAutocomplete
                    page={"header"}
                    handleSearch={handleClassSearch}
                  />
                )}

                {searchType == "Trainer" && (
                  <div className={styles["input-container"]}>
                    <GoogleAutocomplete setLatLng={setLatLng} page={"header"} />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className={styles["header-right"]}>
          <div className={styles["nav-item"]}>
            {props.user ? (
              <span
                className={styles["profile-icon"]}
                onClick={() => navigate("/account")}
                style={{ marginRight: "10px" }}
              >
                {props?.user?.name.charAt(0).toUpperCase()}
              </span>
            ) : (
              <span
                className={styles["profile-icon"]}
                onClick={() => props.setUserActionOpen(true)}
                style={{ marginRight: "10px" }}
              >
                <FontAwesomeIcon icon={faUser} />
              </span>
            )}
          </div>

          <Link
            className={styles["try-btn"]}
            to="add-review"
            onClick={() =>
              ReactGA.event("add_review_click", {
                category: "User Click",
                action: "Clicked the add review button",
              })
            }
          >
            Add a Review{" "}
          </Link>
        </div>
      </div>

      <div className={styles["header-second-level-mobile"]}>
        <BurgerMenu
          user={props.user}
          handleSearch={handleSearch}
          handleClassSearch={handleClassSearch}
        />
        <Link className={styles.logo} to="/">
          <img
            alt="Company logo"
            src={process.env.PUBLIC_URL + "/alt-logo.png"}
          />
        </Link>

        <div className={styles["header-right"]}>
          <Link
            className={styles["try-btn"]}
            to="add-review"
            onClick={() =>
              ReactGA.event({
                category: "User",
                action: "Clicked the add review",
              })
            }
          >
            Add A Review{" "}
          </Link>
        </div>
      </div>
    </header>
  );
}

export default Header;
