import styles from "./About.module.scss";

function About() {
  return (
    <div className={styles.container} id="about">
        <div className={styles.content}>
      <h1>About Trainer Rater</h1>

      <p>
        Trainer Rater is a platform that will create a growing fitness
        community which shares positive experiences and encourages users to try
        new workouts and new trainers.</p>
        
        <p>Users can discover and
        share reviews on trainers and classes in order to facilities the growth
        of the fitness community, and find the perfect workout regimen.</p>

        <p>Trainers can expand their reach further than
        ever before and receive much needed feedback from their clients.
      </p>
      </div>
    </div>
  );
}

export default About;
