import algoliasearch from "algoliasearch/lite";
import { createAutocomplete } from "@algolia/autocomplete-core";
import { getAlgoliaResults } from "@algolia/autocomplete-preset-algolia";
import React, { useRef, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDumbbell, faSearch } from "@fortawesome/free-solid-svg-icons";

import "@algolia/autocomplete-theme-classic";
import "./Autocomplete.scss";
import { useNavigate } from "react-router-dom";
import styles from "./AutocompleteScss.module.scss";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import useOnclickOutside from "react-cool-onclickoutside";

export function ClassesAutocomplete(props) {
  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_ID,
    process.env.REACT_APP_CLASS_AUTOCOMPLETE
  );

  const inputRef = useRef(null);
  const navigate = useNavigate();

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    autocomplete.setIsOpen(false);
    setAddClassDropdown(false);
    inputRef.current.blur();
  });

  useEffect(() => {
    const getData = async () => {
      const classesData = await getDoc(doc(db, "classes", props.presetInput));
      const classname = { ...classesData.data(), objectID: classesData.id };
      props.handleChange("classname", classname);
      autocomplete.setQuery(classname.class);
    };

    if (
      props?.presetInput &&
      props.page === "form" &&
      props?.presetType === "Class"
    ) {
      getData();
    }
  }, []);
  // (1) Create a React state.

  const [autocompleteState, setAutocompleteState] = useState({});
  const [addClassDropdown, setAddClassDropdown] = useState(false);
  const autocomplete = React.useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          // (2) Synchronize the Autocomplete state with the React state.
          setAutocompleteState(state);
        },
        getSources() {
          return [
            // (3) Use an Algolia index source.
            {
              sourceId: "classes",
              getItemInputValue({ item }) {
                return item.query;
              },
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: "classes",
                      query,
                      params: {
                        hitsPerPage: 4,
                        highlightPreTag: "<mark>",
                        highlightPostTag: "</mark>",
                      },
                    },
                  ],
                });
              },
              getItemUrl({ item }) {
                return item.url;
              },
            },
          ];
        },
      }),
    []
  );

  return (
    <div
      className={
        "aa-Autocomplete" +
        (props?.page == "header" ? " header" : "") +
        (props?.page == "form" ? " form" : "") +
        (props?.page == "burger" ? " burger" : "")
      }
      {...autocomplete.getRootProps({})}
      ref={ref}
    >
      <form
        className={
          "aa-Form" +
          (props?.page == "header" ? " header" : "") +
          (props?.page == "burger" ? " burger" : "")
        }
        {...autocomplete.getFormProps({ inputElement: inputRef.current })}
        style={{ background: props.page == "form" && "white" }}
      >
        <div className="aa-InputWrapperPrefix">
          <label className="aa-Label" {...autocomplete.getLabelProps({})}>
            <button
              style={{ cursor: props.page != "form" && "pointer" }}
              onClick={() => {
                props.page != "form" &&
                  props.handleSearch(inputRef.current.value);
                props.page == "burger" && props.closeBurger();
              }}
              className="aa-SubmitButton"
              type="submit"
              title="Submit"
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </label>
        </div>
        <div className="aa-InputWrapper">
          <input
            className={
              "aa-Input" +
              (props?.page == "header" ? " header" : "") +
              (props?.page == "burger" ? " burger" : "")
            }
            ref={inputRef}
            {...autocomplete.getInputProps({
              inputElement: inputRef.current,
              placeholder: "Search for a class",
              onClick: () => {
                setAddClassDropdown(true);
              },
            })}
          />
        </div>
        <div className="aa-InputWrapperSuffix">
          <button
            className="aa-ClearButton"
            title="Clear"
            onClick={() => {
              props.page == "form" && props.setAddClass(false);
              props.page == "form" && props.handleChange("add-class", "");
              props.page == "form" && autocomplete.setQuery("");
              props.page == "form" && props.handleChange("classname", "");
              props.page == "form" && props.setAddClassDropdown(false);
              inputRef.current.blur();
            }}
          >
            X
          </button>
        </div>
      </form>
      {autocompleteState.isOpen &&
        autocompleteState.collections.map(({ source, items }, index) => {
          if (items.length > 0) {
            return (
              <div
                key={`source-${index}`}
                className="aa-Panel"
                {...autocomplete.getPanelProps({})}
              >
                {/* const {(source, items)} = collection */}
                <div className="aa-Source">
                  <ul className="aa-List" {...autocomplete.getListProps()}>
                    {items
                      // .filter((item) => checkDistance(item))
                      .map((item, index) => (
                        <li
                          key={index}
                          className="aa-Item"
                          {...autocomplete.getItemProps({
                            item,
                            source,
                          })}
                          onClick={() => {
                            props.page != "form" &&
                              navigate("./../../../classes/" + item.class);
                            autocomplete.setIsOpen(false);
                            props.page != "form" && autocomplete.setQuery("");
                            props.page == "form" &&
                              props.handleChange("classname", item);
                            props.page == "form" && props.setAddClass(false);
                            props.page == "form" &&
                              setAddClassDropdown(false);
                            props.page == "form" &&
                              props.handleChange("add-class", "");
                            props.page == "form" &&
                              autocomplete.setQuery(item.class);
                            // props.page == "form" &&
                            //   autocomplete.setQuery(inputRef.current.value);

                            props.page == "burger" && props.closeBurger();
                            inputRef.current.blur();
                          }}
                        >
                          <span>{item.gym}</span>

                          <div className={styles["li-flex"]}>
                            <span className={styles.name}>{item.class}</span>

                            <div>
                              <span className={styles["rating-number"]}>
                                {item.rating.length > 0
                                  ? (
                                      Math.round(
                                        item?.rating.reduce((a, b) => a + b)
                                      ) / item?.rating.length
                                    ).toFixed(2)
                                  : "New"}
                              </span>
                              {item.rating.length > 0 && (
                                <span className={styles.rating}>
                                  <FontAwesomeIcon icon={faDumbbell} />
                                </span>
                              )}
                            </div>
                          </div>
                        </li>
                      ))}
                    {props.page == "form" && items.length > 0 && (
                      <li
                        className="aa-Item"
                        onClick={() => {
                          props.page == "form" &&
                            props.handleChange(
                              "add-class",
                              inputRef.current.value
                            );
                          props.page == "form" &&
                            props.handleChange("classname", "");
                          props.page == "form" && props.setAddClass(true);
                          props.page == "form" &&
                            autocomplete.setQuery(inputRef.current.value);
                          autocomplete.setIsOpen(false);
                          props.page == "form" && setAddClassDropdown(false);
                          inputRef.current.blur();
                        }}
                      >
                        <span>Add Class</span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            );
          }
        })}
      {inputRef?.current?.value?.length > 0 &&
        !autocompleteState.isOpen &&
        autocompleteState.collections[0]?.items.length == 0 &&
        addClassDropdown &&
        props.page == "form" && (
          <div className="aa-Panel" {...autocomplete.getPanelProps({})}>
            {/* const {(source, items)} = collection */}
            <div className="aa-Source">
              <ul className="aa-List" {...autocomplete.getListProps()}>
                <li
                  className="aa-Item"
                  onClick={() => {
                    props.page == "form" &&
                      props.handleChange("add-class", inputRef.current.value);
                    props.page == "form" && props.handleChange("classname", "");
                    props.page == "form" && props.setAddClass(true);
                    props.page == "form" &&
                      autocomplete.setQuery(inputRef.current.value);
                    autocomplete.setIsOpen(false);
                    props.page == "form" && setAddClassDropdown(false);

                    inputRef.current.blur();
                  }}
                >
                  <span>Add Class</span>
                </li>
              </ul>
            </div>
          </div>
        )}
    </div>
  );
}

// ...
