import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, useEffect } from 'react';
import { useSearchBox } from 'react-instantsearch';
import styles from "./CustomSearchbox.module.scss";

function CustomSearchBox(props) {
  const { query, refine, isSearchStalled } = useSearchBox(props);
  const [inputValue, setInputValue] = useState(props?.presetValue);
  const inputRef = useRef(null);

  useEffect(() => {
      refine(props?.presetValue)
  }, [])

  function setQuery(newQuery) {
    setInputValue(newQuery);

    refine(newQuery);
  }

  function resetQuery(event){

      event.preventDefault();

      setQuery('');

      if (inputRef.current) {
        inputRef.current.focus();
      }
  }

  return (
    <div className={styles["form-container"]}>
      <form
        className={styles["input-container"]}
        action=""
        role="search"
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();

          if (inputRef.current) {
            inputRef.current.blur();
          }
        }}

      >
        <input
          ref={inputRef}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder={"Search for a " + props.searchType.toLowerCase()}
          spellCheck={false}
          maxLength={512}
          // type="search"
          value={inputValue}
          onChange={(event) => {
            setQuery(event.currentTarget.value);
          }}
          autoFocus
        />
        <span className={styles.submit} type="submit"><FontAwesomeIcon icon={faMagnifyingGlass}/></span>
        <span
          type="reset"
          hidden={inputValue?.length === 0 || isSearchStalled}
          className={styles.reset}
          onClick={(event) =>  resetQuery(event)}
        >
          X
        </span>
        <span hidden={!isSearchStalled}>Searching…</span>
      </form>
    </div>
  );
}

export default CustomSearchBox;