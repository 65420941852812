import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Login from "./pages/log-in/Login";
import Signup from "./pages/signup/Signup";

import HomePage from "./pages/home/HomePage";
import TrainerProfile from "./pages/trainer/TrainerProfile";
import AddReview from "./pages/add-review/AddReview";
import ResetPassword from "./pages/reset/ResetPassword";
import Account from "./pages/account/Account";
import SearchContainer from "./pages/search/SearchContainer";
import ClassPage from "./pages/class/ClassPage";
import Team from "./pages/team/Team";
import PrivacyPolicy from "./pages/privacy/PrivacyPolicy";
import Terms from "./pages/terms/Terms";
import Announcements from "./pages/announcements/Announcements";
import Announcement1 from "./pages/announcements/Announcement1";
import GymPage from "./pages/gym/GymPage";

import { useEffect } from "react";

function AppRoutes(props) {
  const location = useLocation();

  useEffect(() => {
    let hash = location.hash;
    const removeHashCharacter = (str) => {
      const result = str.slice(1);
      return result;
    };
    const hashElement = document.getElementById(removeHashCharacter(hash));

    if (hashElement) {
      hashElement.scrollIntoView({
        behavior: "smooth",
        // block: "end",
        inline: "nearest",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [location.pathname, location.hash]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Signup />} />

      <Route
        path="/add-review"
        element={<AddReview setIsOpen={props.setUserActionOpen} />}
      />

      <Route
        path="/trainers/:id"
        element={<TrainerProfile setIsOpen={props.setUserActionOpen} />}
      />

      <Route
        path="/classes/:classname"
        element={<ClassPage setIsOpen={props.setUserActionOpen} />}
      />

<Route
        path="/gyms/:gymname"
        element={<GymPage setIsOpen={props.setUserActionOpen} />}
      />

      <Route path="/reset" element={<ResetPassword />} />

      <Route path="/account" element={<Account />} />

      <Route path="/search" element={<SearchContainer />} />

      <Route path="/team" element={<Team />} />

      <Route path="/privacy" element={<PrivacyPolicy />} />

      <Route path="/announcements/announcement-1" element={<Announcement1 />} />

      <Route path="/announcements" element={<Announcements />} />

      <Route path="/terms-and-conditions" element={<Terms />} />

      {/* <Route path="*" element={<InvalidPage />} /> */}
    </Routes>
  );
}

export default AppRoutes;
