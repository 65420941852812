import styles from "./HomePage.module.scss";
import GoogleAutocomplete from "./../../components/google-autocomplete/GoogleAutocomplete";
import { useState } from "react";
import Dropdown from "./../../components/common/dropdown/Dropdown";
import Geocode from "react-geocode";
import { useNavigate } from "react-router-dom";

import { Autocomplete } from "../../components/algolia/Autocomplete";
import { ClassesAutocomplete } from "../../components/algolia/ClassesAutocomplete";
import About from "./../../components/about/About";
import HowItWorks from "../../components/how-it-works/HowItWorks";
import Announcements from "./../announcements/Announcements";

function HomePage() {
  document.title = "Trainer Rater";

  const navigate = useNavigate();
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);

  const [location, setLocation] = useState("");

  const [latlng, setLatLng] = useState({});

  const [mileRadius, setMileRadius] = useState(25);

  const [searchType, setSearchType] = useState("Trainer");

  function handleSearch(searchVal) {
    navigate(
      "./search?" +
        searchType.toLowerCase() +
        "=" +
        searchVal +
        "&location=" +
        location
    );
  }

  function handleClassSearch(searchVal) {
    navigate("./search?" + searchType.toLowerCase() + "=" + searchVal);
  }

  return (
    <div className={styles.home}>
      <div className={styles["home-main"]}>
        <div className={styles["home-image"]}>
          <div className={styles["content-middle"]}>
            <h2>Trainer Rater</h2>
            <span className={styles.caption}>
              Never have a bad workout again.
            </span>

            <div className={styles.search}>
              <div className={styles.filters}>
                <div className={styles["filter-div"]}>
                  <span>Search For: </span>
                  <Dropdown
                    selected={searchType}
                    setIsSelected={setSearchType}
                    handleChange={() => {}}
                    options={["Trainer", "Class"]}
                    page={"home"}
                  />
                </div>
                {searchType == "Trainer" && (
                  <div className={styles["filter-div"]}>
                    <span>Mile Radius: </span>
                    <Dropdown
                      selected={mileRadius}
                      setIsSelected={setMileRadius}
                      handleChange={() => {}}
                      options={[5, 10, 25, 50]}
                      page={"home"}
                    />
                  </div>
                )}
              </div>

              <div
                className={
                  styles.inputs +
                  (searchType == "Class" ? " " + styles.classes : "")
                }
              >
                {searchType == "Trainer" ? (
                  <Autocomplete
                    latlng={latlng}
                    mileRadius={mileRadius}
                    type={searchType}
                    handleSearch={handleSearch}
                  />
                ) : (
                  <ClassesAutocomplete handleSearch={handleClassSearch} />
                )}

                {searchType == "Trainer" && (
                  <div className={styles["input-container"]}>
                    <GoogleAutocomplete
                      setLocation={setLocation}
                      setLatLng={setLatLng}
                    />
                  </div>
                )}
              </div>
              {searchType == "Trainer" && (
                <span className={styles.all} onClick={() => handleSearch("")}>
                  View All Trainers
                </span>
              )}
              {searchType == "Class" && (
                <span
                  className={styles.all}
                  onClick={() => handleClassSearch("")}
                >
                  View All Classes
                </span>
              )}
            </div>
          </div>

          <div className={styles["content-mobile"]}>
            <h2>Trainer Rater</h2>
            <span className={styles.caption}>
              Never have a bad workout again.
            </span>
          </div>
        </div>
      </div>
      <div className={styles["mobile-search-container"]}>
        <div className={styles.search}>
          <div className={styles.filters}>
            <div className={styles["filter-div"]}>
              <span>Search For: </span>
              <Dropdown
                selected={searchType}
                setIsSelected={setSearchType}
                handleChange={() => {}}
                options={["Trainer", "Class"]}
                page={"home"}
              />
            </div>
            {searchType == "Trainer" && (
              <div className={styles["filter-div"]}>
                <span>Mile Radius: </span>
                <Dropdown
                  selected={mileRadius}
                  setIsSelected={setMileRadius}
                  handleChange={() => {}}
                  options={[5, 10, 25, 50]}
                  page={"home"}
                />
              </div>
            )}
          </div>

          <div className={styles.inputs}>
            {searchType == "Trainer" ? (
              <Autocomplete
                latlng={latlng}
                mileRadius={mileRadius}
                type={searchType}
                handleSearch={handleSearch}
              />
            ) : (
              <ClassesAutocomplete handleSearch={handleClassSearch} />
            )}
            {searchType == "Trainer" && (
              <div className={styles["input-container"]}>
                <GoogleAutocomplete
                  setLocation={setLocation}
                  setLatLng={setLatLng}
                />
              </div>
            )}
          </div>
          {searchType == "Trainer" && (
            <span className={styles.all} onClick={() => handleSearch("")}>
              View All Trainers
            </span>
          )}
          {searchType == "Class" && (
            <span className={styles.all + " "  + styles.marginBottom} onClick={() => handleClassSearch("")}>
              View All Classes
            </span>
          )}
        </div>
      </div>
      <HowItWorks />
      <About />
      <Announcements />
    </div>
  );
}

export default HomePage;
