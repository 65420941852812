import React from "react";
import Modal from "react-modal";
import "./Modal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#EDF2F4",
  },
};

const spanStyles = {
  position: "absolute",
  top: "5px",
  right: "10px",
  fontSize: "32px",
  cursor: "pointer",
  color: "#2B2D42",
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function ModalBase(props) {
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.close}
      style={customStyles}
      contentLabel="Example Modal"
      closeTimeoutMS={500}
    >
      <div className={"modal-wrapper"}>
        <span style={spanStyles} onClick={props.close}>
          <FontAwesomeIcon icon={faXmark} />
        </span>

        {props.children}
      </div>
    </Modal>
  );
}

export default ModalBase;
