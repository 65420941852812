import styles from "./Terms.module.scss";

function Terms() {
  document.title = "Trainer Rater | Terms";

  return (
    <div className={styles.container}>
      <h1>Terms and Conditions</h1>
      <p>Agreement between User and www.trainer-rater.com</p>
      <p>
        Welcome to Trainer Rater. The www.trainer-rater.com website (the "Site")
        is comprised of various web pages. The Site is offered to you
        conditioned on your acceptance without modification to the terms,
        conditions, and notices contained herein (the "Terms"). Your use of the
        site constitues your agreement to all such Terms. Please read these
        terms carefully, and keep a copy of them for your reference.
      </p>

      <p>Trainer Rater is a fitness review and locator tool.</p>

      <p>
        Trainer Rater provides a way for users to find the best workout class,
        wherever in the US they desire. It also provides a way for trainers to
        receive insight into how their work is being received.
      </p>

      <h2>Privacy</h2>
      <p>
        Your use of the Site is subject to Trainer Rater's Privacy Policy. Please
        review our Privacy Policy, which also governs the Site and informs users
        of our data colleciton Practices.
      </p>

      <h2>Electronic Communications</h2>
      <p>
        Visiting www.trainer-rater.com or sending emails to Trainer Rater constitutes
        electronic communications. You consent to receive
        electronic communications and you agree that all agreements, notices,
        disclosures and other communications that we provide to you
        electronically, via email and on the Site, satisfy any legal requirement
        that such communications be in writing.
      </p>

      <h2>Your Account</h2>
      <p>
        If you use this site, you are responsible for maintaining the
        confidentiality of your account and password and for restricting access
        to your computer, and you agree to accept responsibility for all
        activities that occur under your account or password. You may not assign
        or otherwise transfer your account to any other person or entity. You
        acknowledge that Trainer Rater is not responsible for third party access to
        your account that results from theft or misappropriation of your
        account. Trainer Rater and its associates reserve the right to refuse or
        cancel service, terminate accounts, or remove or edit content in our
        sole discretion.
      </p>

      <h2>Links to Third Party Sites/Third Party Services</h2>
      <p>
        Trainer Rater may contain links to other websites ("Linked Sites"). The
        Linked Sites are not under the control of Trainer Rater and Trainer Rater is not
        responsible for the contents of any Linked Site, including without
        limitation any link contained in a Linked Site, or any changes or
        updates to a Linked Site. Trainer Rater is providing these links to you only
        as a convenience, and the inclusion of any link does not imply
        endorsement by Trainer Rater of the site or any association with its
        operators.
      </p>

      <p>
        Certain services made available via www.trainer-rater.com are delivered by
        third party sites and organizations. By using any product, service or
        Functionality originating from the www.trainer-rater.com domain, you hereby
        acknowledge and consent that Trainer Rater may share such information and
        data with any third party with whom Trainer Rater has a contractual
        relationship to provide the requested product, service or functionality
        on behalf of www.trainer-rater.com users and customers.
      </p>

      <h2>No Unlawful or Prohibited Use/Intellectual Property</h2>
      <p>
        You are granted a non-exclusive, non-transferable, revocable license to
        access and use www.trainer-rater.com strictly in accordance with these terms
        of use. As a condition of your use of the Site, you warrant to Trainer Rater
        that you will not use the Site for any purpose that is unlawful or
        prohibited by these Terms. You may not use the Site in any manner which
        could damage, disable, overburden, or impair the Site or interfere with
        any other party's use and enjoyment of the Site. You may not obtain or
        attempt to obtain any materials or information through any means not
        intentionally made available or provided for through the Site.
      </p>

      <p>
        All content included as part of the Service, such as text, graphics,
        logos, images, as well as the compilation thereof, and any software used
        on the Site, is the property of Trainer Rater or its suppliers and protected
        by copyright and other laws that protect intellectual property and
        proprietary rights. You agree to observe and abide by all copyright and
        other proprietary notices, legends or other restrictions contained in
        any such content and will not make any changes thereto.
      </p>

      <p>
        You will not modify, publish, transmit, reverse engineer, participate in
        the transfer or sale, create derivative works, or in any way exploit any
        of the content, in whole or in part, found on the Site. Trainer Rater
        content is not for resale. Your use of the Site does not entitle you to
        make any unauthorized use of any protected content, and in particular
        you will not delete or alter any proprietary rights or attribution
        notices in any content. You will use protected content solely for your
        personal use, and will make no other use of the content without the
        express written permission of Trainer Rater and the copyright owner. You
        agree that you do not acquire any ownership rights in any protected
        content. We do not grant you any licenses, express or implied, to the
        intellectual property of Trainer Rater or our licensors except as expressly
        authorized by these Terms
      </p>

      <h2>Third Part Accounts</h2>
      <p>
        You will be able to connect your Trainer Rater account to third party
        accounts. By connecting your Trainer Rater account to your third party
        account, you acknowledge and agree that you are consenting to the
        contimuous release of information about you to others (in accordance
        with your privacy settings on those third party sites). If you do not
        want information about you to be shared in this manner, do not use this
        feature.
      </p>

      <h2>Indemnification</h2>
      <p>
        You agree to indemnify, defend and hold harmless Trainer Rater, its
        officers, directors, employees, agents and third parties, for any
        losses, costs, liabilities and expenses (including reasonable attorney's
        fees) relating to or arising out of your use of or inability to use the
        Site or services, any user postings made by you, your violation of any
        terms of this Agreement or your violation of any rights of a third
        party, or your violation of any applicable laws, rules or regulations.
        Trainer Rater reserves the right, at its own cost, to assume the exclusive
        defense and control of any matter otherwise subject to indemnification
        by you, in which event you will fully cooperate with Trainer Rater in
        asserting any available defenses.
      </p>

      <h2>Liability Disclaimer</h2>
      <p>
        THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
        AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL
        ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. TRAINERRATER 
        MAY MAKE IMPROVEMENTS AND/OR CHANGES TO THE SITE AT ANY TIME.
      </p>

      <p>
        TRAINERRATER MAKES NO REPRESENTATIONS ABOUT THE
        SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE
        INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED
        ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY
        APPLICABLE LAW, ALL SUCH INFORMATION. SOFTWARE PRODUCTS, SERVICES AND
        RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF
        ANY KIND. TRAINERRATER HEREBY DISCLAIM ALL
        WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION. SOFTWARE,
        PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED
        WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
        PURPOSE, TITLE AND NON-INFRINGEMENT.
      </p>

      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
        TRAINERRATER BE LIABLE FOR ANY DIRECT, INDIRECT,
        PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
        WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA
        OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR
        PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR
        RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR
        FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
        OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE
        SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR
        OTHERWISE, EVEN IF TRAINERRATER OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED
        OF THE POSSIBILITY OF DAMAGES, BECAUSE SOME STATES/JURISDICTIONS DO NOT
        ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
        INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU
        ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE
        TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE
        SITE
      </p>

      <h2>Termination/Access Restriction</h2>
      <p>
        Trainer Rater reserves the right, in its sole discretion, to terminate your
        access to the Site and the related services or any portion thereof at
        any time, without notice.
      </p>

      <p>
        Unless otherwise specified herein, this agreement constitutes the entire
        agreement between the user and Trainer Rater with respect to the Site and it
        supersedes all prior or contemporaneous communications and proposals,
        whether electronic, oral or written, between the user and Trainer Rater with
        respect to the Site. A printed version of this agreement and of any
        notice given in electronic form shall be admissible in judicial or
        administrative proceedings based upon or relating to this agreement to
        the same extent and subject to the same conditions as other business
        documents and records originally generated and maintained in printed
        form. It is the express wish to the parties that this agreement and all
        related documents be written in English.
      </p>

      <h2>Changes to Terms</h2>
      <p>
        Trainer Rater reserves the right, in its sole discretion, to change the
        Terms under which www.trainer-rater.com is offered. The most current version
        of the Terms will supersede all previous versions. Trainer Rater encourages
        you to periodically review the Terms to stay informed of our updates.
      </p>

      <h2>Contact Us</h2>
      <p>
        Any questions or concerns can be sent to: info.trainerrater@gmail.com
      </p>
    </div>
  );
}

export default Terms;
