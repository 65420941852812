import { useState, useRef, useEffect } from "react";
import styles from "./MultiSelect.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faSquareCheck, faSquare } from "@fortawesome/free-solid-svg-icons";

function MultiSelect({ options, selected, toggleOption }) {
  const [isOpen, setIsOpen] = useState(false)

  const node = useRef();

  const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
      };
    }, [ref, handler]);
  };

  useOnClickOutside(node, () => setIsOpen(false));

  return (
    <div className={styles["multi-select-dropdown"]} ref={node}>
      <div onClick={() => setIsOpen(!isOpen)} className={styles["multi-select-dropdown__selected"]}>
        <div className={styles["selected-flex"]}>{selected.length > 0 ? selected.map((item,index) => <span key={index} className={styles.chip}>{item}</span>) : "Select a tag"}</div> 
        <div><FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} /></div>
        {/* <img src={Dropdown}/> */}
      </div>
      <ul className={styles["multi-select-dropdown__options"]} style={{display: !isOpen ? "none" : "block"}}>
        {options.map((option, index) => {
          const isSelected = selected.includes(option);

          return (
            <li
              className={styles["multi-select-dropdown__option"]}
              onClick={() => toggleOption(option)}
              key={index}
            >
              <span className={styles.checkbox}>{isSelected ? <FontAwesomeIcon icon={faSquareCheck} /> : <FontAwesomeIcon icon={faSquare} />}</span>
              <span>{option}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default MultiSelect;
