import styles from "./UserActionModal.module.scss";
import ModalBase from "./../common/modal/Modal";
import Login from "./../../pages/log-in/Login";
import Signup from "./../../pages/signup/Signup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "../../pages/forgot/ForgotPassword";

function UserActionModal(props) {
  const navigate = useNavigate();
  const [active, setActive] = useState("Login" || props.presetActive);
  const [selectedTab, setSelectedTab] = useState("Login" || props.presetTab);

  function forgotClicked() {
    props.setIsOpen(false);
    navigate("/forgot");
  }
  return (
    <ModalBase isOpen={props.isOpen} close={() => props.setIsOpen(false)}>
      {active != "Forgot" && (
        <div className={styles.tabs}>
          <span
            onClick={() => {
              setSelectedTab("Login");
              setActive("Login");
            }}
            className={
              styles.tab + " " + (selectedTab == "Login" && styles.active)
            }
          >
            Sign In
          </span>
          <span
            onClick={() => {
              setSelectedTab("Register");
              setActive("Register");
            }}
            className={
              styles.tab + " " + (selectedTab == "Register" && styles.active)
            }
          >
            Join Us
          </span>
        </div>
      )}
      {active == "Login" && (
        <Login
          modal={true}
          setActive={setActive}
          forgotClicked={forgotClicked}
          setIsOpen={props.setIsOpen}
        />
      )}
      {active == "Register" && (
        <Signup
          modal={true}
          setActive={setActive}
          setIsOpen={props.setIsOpen}
        />
      )}
      {active == "Forgot" && (
        <ForgotPassword
          modal={true}
          setActive={setActive}
          setSelectedTab={setSelectedTab}
          setIsOpen={props.setIsOpen}
        />
      )}
    </ModalBase>
  );
}

export default UserActionModal;
