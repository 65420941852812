import algoliasearch from "algoliasearch/lite";
import { createAutocomplete } from "@algolia/autocomplete-core";
import { getAlgoliaResults } from "@algolia/autocomplete-preset-algolia";
import React, { useRef, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDumbbell, faSearch } from "@fortawesome/free-solid-svg-icons";

import "@algolia/autocomplete-theme-classic";
import "./Autocomplete.scss";
import { useNavigate } from "react-router-dom";
import getDistance from "geolib/es/getDistance";
import styles from "./AutocompleteScss.module.scss";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import useOnclickOutside from "react-cool-onclickoutside";

export function Autocomplete(props) {
  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_ID,
    process.env.REACT_APP_TRAINER_AUTOCOMPLETE
  );

  const inputRef = useRef(null);
  const navigate = useNavigate();

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    autocomplete.setIsOpen(false);
    setAddTrainerDropdown(false);
    inputRef.current.blur();
  });

  useEffect(() => {
    const getData = async () => {
      const trainersData = await getDoc(doc(db, "trainers", props.presetInput));
      const trainer = { data: trainersData.data(), id: trainersData.id };
      props.handleChange("trainer", trainer);
      autocomplete.setQuery(trainer.data.name);
    };

    if (
      props?.presetInput &&
      props.page == "form" &&
      props?.presetType == "Trainer"
    ) {
      getData();
    }
  }, []);

  // (1) Create a React state.
  const [autocompleteState, setAutocompleteState] = React.useState({});
  const [addTrainerDropdown, setAddTrainerDropdown] = useState(false);
  const autocomplete = React.useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          // (2) Synchronize the Autocomplete state with the React state.
          setAutocompleteState(state);
        },
        getSources() {
          return [
            // (3) Use an Algolia index source.
            {
              sourceId: "trainers",
              getItemInputValue({ item }) {
                return item.query;
              },
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: "trainers",
                      query,
                      params: {
                        hitsPerPage: 4,
                        highlightPreTag: "<mark>",
                        highlightPostTag: "</mark>",
                      },
                    },
                  ],
                });
              },
              getItemUrl({ item }) {
                return item.url;
              },
            },
          ];
        },
      }),
    []
  );

  function checkDistance(item) {
    if (JSON.stringify(props?.latlng) === "{}") {
      return true;
    }
    const distance =
      getDistance(
        { latitude: item.latlng.lat, longitude: item.latlng.lng },
        { latitude: props.latlng.lat, longitude: props.latlng.lng }
      ) * 0.00062137;

    return (Math.round(distance * 100) / 100).toFixed(2);
  }

  return (
    <div
      className={
        "aa-Autocomplete" +
        (props?.page == "header" ? " header" : "") +
        (props?.page == "form" ? " form" : "") +
        (props?.page == "burger" ? " burger" : "")
      }
      {...autocomplete.getRootProps({})}
      ref={ref}
    >
      <form
        className={
          "aa-Form" +
          (props?.page == "header" ? " header" : "") +
          (props?.page == "burger" ? " burger" : "")
        }
        {...autocomplete.getFormProps({ inputElement: inputRef.current })}
        style={{ background: props.page == "form" && "white" }}
      >
        <div className="aa-InputWrapperPrefix">
          <label className="aa-Label" {...autocomplete.getLabelProps({})}>
            <button
              style={{ cursor: props.page != "form" && "pointer" }}
              onClick={() => {
                props.page != "form" &&
                  props.handleSearch(inputRef.current.value);
                props.page == "burger" && props.closeBurger();
              }}
              className="aa-SubmitButton"
              type="submit"
              title="Submit"
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </label>
        </div>
        <div className="aa-InputWrapper">
          <input
            className={
              "aa-Input" +
              (props?.page == "header" ? " header" : "") +
              (props?.page == "burger" ? " burger" : "")
            }
            ref={inputRef}
            {...autocomplete.getInputProps({
              inputElement: inputRef.current,
              placeholder: "Search for a trainer",
              onClick: () => {
                setAddTrainerDropdown(true);
              },
            })}
          />
        </div>
        <div className="aa-InputWrapperSuffix">
          <button
            className="aa-ClearButton"
            title="Clear"
            onClick={() => {
              props.page == "form" && props.setAddTrainer(false);
              props.page == "form" && props.handleChange("trainer", "");
              props.page == "form" && autocomplete.setQuery("");
              props.page == "form" && props.setNewTrainer("");
              props.page == "form" && props.setAddTrainerDropdown(false);
              inputRef.current.blur();
            }}
          >
            X
          </button>
        </div>
      </form>
      {autocompleteState.isOpen &&
        autocompleteState.collections.map(({ source, items }, index) => {
          {
            items = props.mileRadius
              ? items.filter(
                  (item) => checkDistance(item) - props.mileRadius < 0
                )
              : items;
          }

          return (
            <div
              key={`source-${index}`}
              className="aa-Panel"
              {...autocomplete.getPanelProps({})}
            >
              {/* const {(source, items)} = collection */}
              <div className="aa-Source">
                <ul className="aa-List" {...autocomplete.getListProps()}>
                  {items
                    // .filter((item) => checkDistance(item))
                    .map((item, index) => (
                      <li
                        key={index}
                        className="aa-Item"
                        {...autocomplete.getItemProps({
                          item,
                          source,
                        })}
                        onClick={() => {
                          props.page != "form" && navigate(item.path);
                          autocomplete.setIsOpen(false);
                          props.page != "form" && autocomplete.setQuery("");
                          props.page == "form" &&
                            props.handleChange("trainer", item);
                          props.page == "form" &&
                            autocomplete.setQuery(item.name);
                          props.page == "form" && props.setNewTrainer("");
                          props.page == "form" && props.setAddTrainer(false);
                          props.page == "form" &&
                            setAddTrainerDropdown(false);
                          props.page == "burger" && props.closeBurger();
                          inputRef.current.blur();
                        }}
                      >
                        {JSON.stringify(props?.latlng) !== "{}" && (
                          <span>{checkDistance(item)} mi away</span>
                        )}
                        <div className={styles["li-flex"]}>
                          <span className={styles.name}>{item.name}</span>

                          <div>
                            <span className={styles["rating-number"]}>
                              {item.rating.length > 0
                                ? (
                                    Math.round(
                                      item?.rating.reduce((a, b) => a + b)
                                    ) / item?.rating.length
                                  ).toFixed(2)
                                : "New"}
                            </span>
                            {item.rating.length > 0 && (
                              <span className={styles.rating}>
                                <FontAwesomeIcon icon={faDumbbell} />
                              </span>
                            )}
                          </div>
                        </div>

                        <div className={styles["li-flex"]}>
                          <span className={styles.city}>{item.city}</span>
                          <div>
                            {item.type.length > 1 ? (
                              <span>
                                {item.type[0]} + {item.type.length - 1} more
                              </span>
                            ) : (
                              <span>{item.type}</span>
                            )}
                          </div>
                        </div>
                      </li>
                    ))}
                  {props.page == "form" && items.length > 0 && (
                    <li
                      className="aa-Item"
                      onClick={() => {
                        props.page == "form" &&
                        props.handleChange("trainer", "");
                      props.page == "form" &&
                        autocomplete.setQuery(inputRef.current.value);
                      props.page == "form" && props.setNewTrainer(inputRef.current.value);
                      props.page == "form" && props.setAddTrainer(true);
                      props.page == "form" &&
                        setAddTrainerDropdown(false);
                        inputRef.current.blur();
                      }}
                    >
                      <span>Add Class</span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          );
        })}
        {inputRef?.current?.value?.length > 0 &&
        !autocompleteState.isOpen &&
        autocompleteState.collections[0]?.items.length == 0 &&
        addTrainerDropdown &&
        props.page == "form" && (
          <div className="aa-Panel" {...autocomplete.getPanelProps({})}>
            {/* const {(source, items)} = collection */}
            <div className="aa-Source">
              <ul className="aa-List" {...autocomplete.getListProps()}>
                <li
                  className="aa-Item"
                  onClick={() => {
                    props.page == "form" &&
                    props.handleChange("trainer", "");
                  props.page == "form" &&
                    autocomplete.setQuery(inputRef.current.value);
                  props.page == "form" && props.setNewTrainer(inputRef.current.value);
                  props.page == "form" && props.setAddTrainer(true);
                  props.page == "form" &&
                    setAddTrainerDropdown(false);
                    inputRef.current.blur();
                  }}
                >
                  <span>Add Class</span>
                </li>
              </ul>
            </div>
          </div>
        )}
    </div>
  );
}

// ...
