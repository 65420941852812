import styles from "./ClassHeader.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDumbbell } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function ClassHeader(props) {
  const navigate = useNavigate();

  function check(a, b) {
    return a + b;
  }

  // Reducing the numbers of the array
  let sum;
  let again;
  if (props.data.rating.length > 0) {
    sum = props.data.rating.reduce(check);
    again =
      (props.data.takeAgain.filter((item) => item == true).length /
        props.data.takeAgain.length) *
      100;
    // console.log(again)
  } else {
    sum = 0;
    again = 0;
  }

  // Calculating the average of the numbers
  var avg = sum / props.data.rating.length;

  const stars = [0, 0, 0, 0, 0].map((item, index) => {
    if (avg / (index + 1) >= 1) {
      return 1;
    } else if (
      avg / (index + 1) < 1 &&
      avg % (index + 1 > 0) &&
      index + 1 - avg < 1
    ) {
      return avg % (index + 1 > 0);
    } else {
      return 0;
    }
  });

  return (
    <div className={styles.container}>
      <div className={styles.flex}>
        <div className={styles.left}>
          <h1 className={styles.name}>{props.data.class}</h1>
          <span className={styles.city}>{props.data.gym}</span>
        </div>

        <div className={styles.right}>
          <div className={styles["stars"]}>
            {stars.map((star, index) => (
              <div key={index} className={styles["star"]}>
                <span className={styles["star-under"]}>
                  <FontAwesomeIcon icon={faDumbbell} />
                </span>
                <span
                  style={{ width: star * 100 + "%" }}
                  className={styles["star-over"]}
                >
                  <FontAwesomeIcon icon={faDumbbell} />
                </span>
              </div>
            ))}
          </div>
          <span className={styles.reviews}>
            {avg.toFixed(2)}/5<span style={{ margin: "0 5px" }}>•</span>
            {Math.round(again)}% would take again
            <span className={styles.again}>
              {" "}
              • {props.data.rating.length} Reviews
            </span>
          </span>
          <span className={styles["add-review-button"]} onClick={() => navigate("/add-review?class=" + props?.data?.id)}>
            Review {props?.data?.class}
          </span>
        </div>
      </div>
    </div>
  );
}

export default ClassHeader;
