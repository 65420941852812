import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Hits,
  Pagination,
  Configure,
} from "react-instantsearch";
import "instantsearch.css/themes/satellite.css";
import "./SearchPage.scss";
import styles from "./SearchPage.module.scss";
import CustomRefinementList from "./CustomFilter";
import { useState } from "react";
import GoogleAutocomplete from "../../components/google-autocomplete/GoogleAutocomplete";
import Dropdown from "../../components/common/dropdown/Dropdown";
import Hit from "./CustomHits";
import CustomSearchBox from "./CustomSearchbox";

function TrainerSearchPage(props) {
  const [location, setLocation] = useState("");

  const [latlng, setLatlng] = useState({});

  const [sortBy, setSortBy] = useState("Sort By");

  const [count, setCount] = useState(0)
  
  const params = new URL(document.location).searchParams;
  const presetValue = params.get("trainer") || "";

  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_ID,
    process.env.REACT_APP_TRAINER_SEARCH
  );

  function check(a, b) {
    return a + b;
  }
  function getRating(item) {
    if (item.rating.length > 0) {
      return (item.rating.reduce(check) / item.rating.length).toFixed(2);
    }
    return 0;
  }

  const transformItems = (items) => {
    setCount(items.length)
    if (sortBy === "Rating (High - Low)") {
      return items.sort((a, b) => getRating(b) - getRating(a));
    } else if (sortBy === "Rating (Low - High)") {
      return items.sort((a, b) => getRating(a) - getRating(b));
    } else if (sortBy === "Number of Reviews") {
      return items.sort((a, b) => b.rating.length - a.rating.length);
    } else {
      return items;
    }
  };

  return (
    <InstantSearch searchClient={searchClient} indexName={"trainers"}>
      <Configure
        aroundLatLng={
          JSON.stringify(latlng) === "{}"
            ? ""
            : [latlng.lat, latlng.lng]?.join(",")
        }
        aroundRadius={JSON.stringify(latlng) === "{}" ? "all" : Math.round(props.mileRadius * 1609.344)}
        hitsPerPage={40}
      />
      <div className={styles.inputs}>

        <CustomSearchBox
          searchType={props.searchType}
          presetValue={presetValue}
          resetIconComponent={() => <span>X</span>}
        />
         <div className={styles["input-container"]}>
          <GoogleAutocomplete setLocation={setLocation} setLatLng={setLatlng} />
        </div>
      </div>

      <div className={styles.sort}>
        <div className={styles["filter-mobile"]}>
        <CustomRefinementList attribute="type" />
        </div>
        <Dropdown
          selected={sortBy}
          setIsSelected={setSortBy}
          handleChange={() => {}}
          options={[
            "Rating (High - Low)",
            "Rating (Low - High)",
            "Number of Reviews",
          ]}
          page={"search-sort"}
        />
      </div>
      
      <div className={styles.flex}>
        <div className={styles.filter}>
          <CustomRefinementList attribute="type" />
        </div>

        <div className={styles.search}>
          <Hits
            hitComponent={Hit}
            classNames={{
              list: "hits-grid",
            }}
            transformItems={transformItems}
          />
        </div>
      </div>
      <center>
        <Pagination />
        <br/>
      </center>
    </InstantSearch>
  );
}

export default TrainerSearchPage;
