import styles from "./TrainerHeader.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDumbbell } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function TrainerHeader(props) {
  const navigate = useNavigate();
  function check(a, b) {
    return a + b;
  }

  //   console.log(props.data.rating);
  // Reducing the numbers of the array
  let sum;
  let again;
  if (props.data.rating.length > 0) {
    sum = props.data.rating.reduce(check);
    again =
      (props.data.takeAgain.filter((item) => item == true).length /
        props.data.takeAgain.length) *
      100;
    // console.log(again)
  } else {
    sum = 0;
    again = 0;
  }

  // Calculating the average of the numbers
  var avg = sum / props.data.rating.length;

  const stars = [0, 0, 0, 0, 0].map((item, index) => {
    if (avg / (index + 1) >= 1) {
      return 1;
    } else if (
      avg / (index + 1) < 1 &&
      avg % (index + 1 > 0) &&
      index + 1 - avg < 1
    ) {
      return avg % (index + 1 > 0);
    } else {
      return 0;
    }
  });

  //   console.log(stars);

  return (
    <div className={styles.container}>
      <div className={styles.flex}>
        <div className={styles.left}>
          <h1 className={styles.name}>{props.data.name}</h1>
          <span className={styles.city}>{props.data.city}</span>
          <div className={styles.tags}>
            {[
              ...new Set(
                props.reviews.map((review) => review.gym).flat(1)
              ),
            ].map((tag, index) => (
              <div key={index}>
                <span className={styles.tag}>{tag}</span>
                {index + 1 !==
                  [
                    ...new Set(
                      props.reviews.map((review) => review.gym).flat(1)
                    ),
                  ].length && <span style={{ margin: "0 5px" }}>•</span>}
              </div>
            ))}
          </div>
          {/* <div className={styles.types}>
            {props.data.type.map((type) => (
              <span className={styles.type}>{type}</span>
            ))}
          </div> */}
        </div>

        <div className={styles.right}>
          <div className={styles["stars"]}>
            {stars.map((star, index) => (
              <div key={index} className={styles["star"]}>
                <span className={styles["star-under"]}>
                  <FontAwesomeIcon icon={faDumbbell} />
                </span>
                <span
                  style={{ width: star * 100 + "%" }}
                  className={styles["star-over"]}
                >
                  <FontAwesomeIcon icon={faDumbbell} />
                </span>
              </div>
            ))}
          </div>
          <span className={styles.reviews}>
            {props.reviews ? avg.toFixed(2) : 0}/5
            <span style={{ margin: "0 5px" }}>•</span>
            {Math.round(again)}% would take again
            <span className={styles.again}>
              {" "}
              • {props?.reviews?.length || 0} Reviews
            </span>
          </span>
          <span
            className={styles["add-review-button"]}
            onClick={() => navigate("/add-review?trainer=" + props.id)}
          >
            Review {props?.data?.name}
          </span>
        </div>
      </div>

      {/* <hr /> */}
    </div>
  );
}

export default TrainerHeader;
