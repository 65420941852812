import styles from "./GymReview.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDumbbell,
  faEllipsis,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import LikeButton from "./LikeButton";
import { useState, useContext } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import ModalBase from "../common/modal/Modal";
import { useNavigate } from "react-router-dom";
import { AuthenticatedUserContext } from "../../providers/AuthenticatedUserProvider";

function TrainerReview(props) {
  const { userInfo, setUserInfo } = useContext(AuthenticatedUserContext);

  const navigate = useNavigate();
  const [review, setReview] = useState(props.review);
  const [modal, setModal] = useState(false);
  const [trashModal, setTrashModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  const stars = [0, 0, 0, 0, 0].map((item, index) => {
    if (props.review.rating / (index + 1) >= 1) {
      return 1;
    } else if (
      props.review.rating / (index + 1) < 1 &&
      props.review.rating % (index + 1 > 0) &&
      index + 1 - props.review.rating < 1
    ) {
      return props.review.rating % (index + 1 > 0);
    } else {
      return 0;
    }
  });

  async function likePost(userId) {
    if (userId == null) {
      setModal(true);
    } else {
      const reviewRef = doc(db, "reviews", props.review.id);
      // console.log(review.likes);

      const index = review.likes.indexOf(userId);

      // console.log(index);

      if (index !== -1) {
        review.likes.splice(index, 1);
        props?.account == "liked" &&
          props?.setLikedToRemove([...props.likedToRemove, props.review.id]);
        props?.account == "review" &&
          props?.setLiked(
            props.liked.filter((entry) => entry.id != props.review.id)
          );
      } else {
        const likedIndex =
          props?.account == "liked"
            ? props?.likedToRemove.indexOf(props.review.id)
            : null;
        review.likes.push(userId);
        props?.account == "liked" && props.likedToRemove.splice(likedIndex, 1);
        props?.account == "liked" &&
          props?.setLikedToRemove(props.likedToRemove);
        props?.account == "review" && props.liked != null && props?.liked.length > 0 &&
          props?.setLiked([props.review, ...props?.liked]);

          props?.account == "review" && props.liked === null &&
          props?.setLiked([props.review]);
      }

      // console.log(props.likedToRemove);

      setReview({ ...review, likes: props.review.likes });

      await updateDoc(reviewRef, {
        likes: [...props.review.likes],
      });
    }
  }

  return (
    <div className={styles.review}>
      {modal && (
        <ModalBase isOpen={modal} close={() => setModal(false)}>
          <h1 className={styles.modalH1}>Not Signed In?</h1>
          <p className={styles.modalP}>
            In order to interact with, or create, a review you must be signed
            in.
          </p>
          <div>
            <span
              className={styles.modalLogin}
              onClick={() => {
                setModal(false);
                props.setIsOpen(true);
              }}
            >
              Take Me There
            </span>
          </div>
        </ModalBase>
      )}
      {trashModal && (
        <ModalBase isOpen={trashModal} close={() => setTrashModal(false)}>
          <h1 className={styles.modalH1}>Delete Review?</h1>
          <p className={styles.modalP}>
            It cannot be recovered. Delete wisely!
          </p>
          <div>
            <span
              className={styles.modalLogin}
              onClick={() => {
                props.confirmDelete(idToDelete, props.review);
                setTrashModal(false);
              }}
            >
              Delete Review
            </span>
          </div>
        </ModalBase>
      )}
      {/* <div className={styles.bottom}>
        <span>{new Date(props.review.date).toDateString()}</span>
        <span className={styles.name}>{props.review.user.name}</span>
      </div> */}
      <div className={styles.top}>
        {/* <div><span className={styles.name}>{props.review.classname} at {props.review.type}</span></div> */}
        <div className={styles["location-flex"]}>
          <div className={styles.data}>
            <span onClick={() => navigate("./../../trainers/" + props.review.trainer.id)} className={styles.name}>{props.review.trainer.name}</span>
            <span>Trainer</span>
          </div>
        </div>

        <div className={styles["stars"]}>
          {stars.map((star, index) => (
            <div key={index} className={styles["star"]}>
              <span className={styles["star-under"]}>
                <FontAwesomeIcon icon={faDumbbell} />
              </span>
              <span
                style={{ width: star * 100 + "%" }}
                className={styles["star-over"]}
              >
                <FontAwesomeIcon icon={faDumbbell} />
              </span>
            </div>
          ))}
          {/* <span style={{ margin: "0 5px" }}>•</span>
           <span className={styles.rating}>{props.review.rating}/5</span> */}
        </div>

        <div className={styles["stars-mobile"]}>
          <span className={styles.rating}>{props.review.rating}</span>
          <span className={styles.star}>
            <FontAwesomeIcon icon={faDumbbell} />
          </span>
        </div>
      </div>

      <div className={styles.content}>
        <p className={styles.comments}>{props.review.comments}</p>

        <div className={styles.tags}>
          {props.review.tags.map((tag, index) => (
            <span className={styles.tag} key={index}>
              {tag}
            </span>
          ))}
        </div>

        <div className={styles.bottom}>
          <div>
            <span className={styles.location}>{props.review.location}</span>
          </div>
        </div>

        <div className={styles.bottom}>
          <LikeButton likePost={likePost} data={props.review} />

          <span>
            Would take again? {props.review.takeAgain ? <b>Yes</b> : <b>No</b>}
          </span>
        </div>
      </div>

      <div className={styles.top}>
        <div className={styles.info}>
          <span>
            {new Date(props.review.date).toDateString()} •{" "}
            {props.review.user.name.split(" ")[0] + " " + props.review.user.name.split(" ")[1].charAt(0)}
          </span>
        </div>

        {userInfo != null && props.review.user?.email === userInfo?.email && (
          <div className={styles.info}>
            <span
              onClick={() => {
                setIdToDelete(props.review.id);
                setTrashModal(true);
              }}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default TrainerReview;
