import { useState, useMemo, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getDetails,
} from "use-places-autocomplete";
import styles from "./GoogleAutocomplete.module.scss";
import Geocode from "react-geocode";
import getDistance from "geolib/es/getDistance";
import useOnclickOutside from "react-cool-onclickoutside";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { useRefinementList } from "react-instantsearch";


function GoogleAutocomplete(props) {
  const [libraries] = useState(["places"]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries,
  });
  if (!isLoaded) return <div>Loading...</div>;
  else {
    return (
      <PlacesAutocomplete
        setLocation={props?.setLocation}
        setLatLng={props.setLatLng}
        location={props.location || ""}
        page={props.page}
      />
    );
  }
}

const PlacesAutocomplete = (props) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["locality", "neighborhood", "postal_code", "sublocality_level_1"],
      fields: ["address_component", "geometry", "name"],
      componentRestrictions: { country: ["us", "ca"] },

      /* Define search scope here */
    },
    debounce: 300,
  });

  // useEffect(() => {
  //   clearSuggestions()
  //   setValue(props.location)
  //   clearSuggestions()
  // }, [props.location])

  function handleLocationClick() {
    if (navigator.geolocation) {
      setValue("Loading...");
      navigator.geolocation.getCurrentPosition(success);
    } else {
      // console.log("Geolocation not supported");
    }
  }

  function success(position) {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;

    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        let city, state, country;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                if (country == "United States") {
                  country = "USA";
                } else {
                  country = "CA";
                }
                break;
            }
          }
        }
        setValue("Current Location");
        props.setLatLng({ lat: lat, lng: lng });
        props?.setLocation(city + ", " + state + ", " + country);
        clearSuggestions();
      },
      (error) => {
        console.error(error);
      }
    );
    // setLocation({ latitude, longitude });
    // console.log(`Latitude: ${lat}, Longitude: ${lng}`);
  }

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = ({ place_id, description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);

    const parameter = {
      // Use the "place_id" of suggestion from the dropdown (object), here just taking first suggestion for brevity
      placeId: place_id,
      // Specify the return data that you want (optional)
      // fields: ["name", "address", "rating"],
    };

    getGeocode(parameter).then((results) => {
      if (results[0]) {
        const { lat, lng } = getLatLng(results[0]);
        props?.setLatLng({ lat: lat, lng: lng });
        props?.setLocation(description);
      }

      //   console.log("Coordinates: " + lat);

      //   console.log(getDistance(
      //     { latitude: lat, longitude: lng },
      //     { latitude: 40.730480, longitude: -73.993090 }
      // ))
    });
    // props.handleLocationChange(description, lat, lng )
    props?.setLocation(description)
    clearSuggestions();
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          className={styles["google-option"]}
          key={place_id}
          onClick={handleSelect(suggestion)}
        >
          <span>
            <strong>{main_text} </strong>
            {secondary_text}
          </span>
        </li>
      );
    });

  return (
    <div className={styles["google-container"]} ref={ref}>
      <input
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="City or Zipcode"
      />
      <div className={styles.icons + " " + (props?.page == "header" ? styles.header : "") + (props?.page == "burger" ? styles.burger: "")}>
        <FontAwesomeIcon
          className={styles.icon}
          onClick={handleLocationClick}
          icon={faLocationCrosshairs}
        />
        <span
          className={styles.icon}
          onClick={() => {
            // props.setLocation("");
            props.setLatLng({});
            setValue("");
          }}
        >
          X
        </span>
      </div>
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && (
        <ul className={styles["google-ul"]}>{renderSuggestions()}</ul>
      )}
    </div>
  );
};
export default GoogleAutocomplete;
