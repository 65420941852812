import "instantsearch.css/themes/satellite.css";
import "./SearchPage.scss";
import styles from "./SearchPage.module.scss";
import { useState } from "react";
import Dropdown from "./../../components/common/dropdown/Dropdown";
import TrainerSearchPage from "./TrainerSearchPage";
import ClassSearchPage from "./ClassSearchPage";


function SearchContainer() {
  document.title = "Trainer Rater | Search";

  const [mileRadius, setMileRadius] = useState(25);

  const params = new URL(document.location).searchParams;
  const trainer = params.get("trainer");
  // const classname = params.get("class");

  const presetType = trainer !== null ? "Trainer" : "Class";

  const [searchType, setSearchType] = useState(presetType);

  return (
    <>
      <div className={styles.filters}>
        <div className={styles["filter-div"]}>
          <span>Search For: </span>
          <Dropdown
            selected={searchType}
            setIsSelected={setSearchType}
            handleChange={() => {}}
            options={["Trainer", "Class"]}
            page={"search"}
          />
        </div>
        {searchType === "Trainer" && <div className={styles["filter-div"]}>
          <span>Mile Radius: </span>
          <Dropdown
            selected={mileRadius}
            setIsSelected={setMileRadius}
            handleChange={() => {}}
            options={[5, 10, 25, 50]}
            page={"search"}
          />
        </div>}
      </div>
        
    {searchType === "Trainer" && <TrainerSearchPage mileRadius={mileRadius} searchType={searchType} />}
    {searchType === "Class" && <ClassSearchPage searchType={searchType}/>}
    </>
  );
}

export default SearchContainer;
