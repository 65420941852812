import { useNavigate } from "react-router-dom";
import styles from "./AnnouncementDetails.module.scss";

function Announcement1() {
  document.title = "Trainer Rater | Announcement 1";

  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <span>
        <span className={styles.breadcrumb} onClick={() => navigate("./../")}>
          Announcements
        </span>{" "}
        <span>/</span>
        <b>Announcement 1</b>
      </span>
      <h2>Trainer Rater launches version v1</h2>

      <span>{new Date(2023,11,28).toDateString()}</span>

      <p>
        New fitness platform that aims to connect users by sharing their
        experiences hits the web
      </p>

      <h3>
        The team at Trainer Rater is proud to announce our initial launch of
        Trainer Rater v1.
      </h3>

      <p>
        At Trainer Rater, our goal is to connect users and trainers in order to
        create a wholesome workout community that improves itself thru each
        other. With that being said, here are the initial features of
        Trainer Rater, with plenty more in the works!
      </p>

      <p>Any User:</p>
      <ul>
        <li>
          Any User can search for a trainer or workout class. Users can filter
          by location in order to find the best option for them.
        </li>
        <li>
          Any User can sign up the traditional way, or via their Google account,
          with additional authenticators on the way.
        </li>
      </ul>
      <p>Signed In Users:</p>
      <ul>
        <li>
          Signed in Users can add reviews to any class or personal training
          session they desire.
        </li>
        <li>
          In a review, besides for a score, users can also give comments, tags,
          and a “take again” to really let the other users know how they felt
          about the workout.
        </li>
        <li>
          Signed in Users can add trainers and/or classes to the database so
          that other users can search for them in the drop-down, or add more
          reviews to the existing class/trainer.
        </li>
        <li>
          Signed in Users can like a review signifying their agreement with the
          sentiment shared.
        </li>
      </ul>
      <p>
        We have a lot in store for 2024 and hope that we can help make your
        fitness journey that much more impactful.
      </p>

      <p>
        Be on the lookout for routine updates and improvements that can include
        new features as well as bug fixes.
      </p>

      <p>
        Any feedback is greatly appreciated and can be sent to any of the social
        links in the footer.
      </p>

      <p>
        Cheers, <br />
        The Trainer Rater Team
      </p>
    </div>
  );
}

export default Announcement1;
