import algoliasearch from "algoliasearch/lite";
import { InstantSearch, Hits, Pagination } from "react-instantsearch";
import "instantsearch.css/themes/satellite.css";
import "./SearchPage.scss";
import styles from "./SearchPage.module.scss";
import CustomRefinementList from "./CustomFilter";
import { useState } from "react";
import Dropdown from "./../../components/common/dropdown/Dropdown";
import CustomSearchBox from "./CustomSearchbox";
import ClassesHit from "./ClassesHits";

function ClassSearchPage(props) {
  const params = new URL(document.location).searchParams;
  const presetValue = params.get("class") || "";
  const [sortBy, setSortBy] = useState("Sort By");

  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_ID,
    process.env.REACT_APP_CLASS_SEARCH
  );

  function check(a, b) {
    return a + b;
  }
  function getRating(item) {
    if (item.rating.length > 0) {
      return (item.rating.reduce(check) / item.rating.length).toFixed(2);
    }
    return 0;
  }

  const transformItems = (items) => {
    if (sortBy == "Rating (High - Low)") {
      return items.sort((a, b) => getRating(b) - getRating(a));
    } else if (sortBy == "Rating (Low - High)") {
      return items.sort((a, b) => getRating(a) - getRating(b));
    } else if (sortBy == "Number of Reviews") {
      return items.sort((a, b) => b.rating.length - a.rating.length);
    } else {
      return items;
    }
  };

  return (
    <InstantSearch searchClient={searchClient} indexName={"classes"}>
      <div className={styles.inputs + " " + styles.classes}>
        <CustomSearchBox
          searchType={props.searchType}
          presetValue={presetValue}
          resetIconComponent={() => <span>X</span>}
        />
      </div>

      <div className={styles.sort}>
        <div className={styles["filter-mobile"]}>
          <CustomRefinementList attribute="gym" />
        </div>
        <Dropdown
          selected={sortBy}
          setIsSelected={setSortBy}
          handleChange={() => {}}
          options={[
            "Rating (High - Low)",
            "Rating (Low - High)",
            "Number of Reviews",
          ]}
          page={"search-sort"}
        />
      </div>

      <div className={styles.flex}>
        <div className={styles.filter}>
          <CustomRefinementList attribute="gym" />
        </div>

        <div className={styles.search}>
          <Hits
            hitComponent={ClassesHit}
            classNames={{
              list: "hits-grid",
            }}
            transformItems={transformItems}
          />
        </div>
      </div>
      <center>
        <Pagination />
        <br/>
      </center>
    </InstantSearch>
  );
}

export default ClassSearchPage;
