import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import styles from "./BurgerMenu.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { NavLink, useLocation } from "react-router-dom";
import GoogleAutocomplete from "./../../components/google-autocomplete/GoogleAutocomplete";
import Dropdown from "./../../components/common/dropdown/Dropdown";

import { Autocomplete } from "../../components/algolia/Autocomplete";
import { ClassesAutocomplete } from "../../components/algolia/ClassesAutocomplete";

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #2b2d42;
  // transform: ${({ open }) => (open ? "translateY(0)" : "translateY(-150%)")};
  //   height: 80vh;
  width: 100%;
  text-align: left;
  padding: 60px 20px 20px;
  position: absolute;
  padding-top: 80px;
  left: 0;
  transition: transform 0.3s ease-in-out;
  box-sizing: border-box;
  z-index: 3;

  hr {
    background-color: #8d99ae;
    height: 2px;
    border: none;
    width: 100%;
    // padding: 0 22px;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
`;

const BlackBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transform: ${({ open }) => (open ? "translateY(0)" : "translateY(-150%)")};
  transition: transform 0.3s ease-in-out;
`;

const Menu = ({ myRef, open, loading, user, setOpen, handleSearch, handleClassSearch }) => {
  let location = useLocation();
  const [latlng, setLatLng] = useState({});

  const [mileRadius, setMileRadius] = useState(25);

  const [searchType, setSearchType] = useState("Trainer");

  let activeStyle = {
    textDecoration: "underline",
  };

  return (
    <BlackBackground open={open}>
      <StyledMenu ref={myRef}>
        {location.pathname !== "/" && location.pathname !== "/search" && (
          <div className={styles["mobile-search-container"]}>
            <div className={styles.search}>
              <div className={styles.filters}>
                <div className={styles["filter-div"]}>
                  <span>Search For: </span>
                  <Dropdown
                    selected={searchType}
                    setIsSelected={setSearchType}
                    handleChange={() => {}}
                    options={["Trainer", "Class"]}
                    page={"burger"}
                  />
                </div>
                {searchType == "Trainer" && <div className={styles["filter-div"]}>
                  <span>Mile Radius: </span>
                  <Dropdown
                    selected={mileRadius}
                    setIsSelected={setMileRadius}
                    handleChange={() => {}}
                    options={[5, 10, 25, 50]}
                    page={"burger"}
                  />
                </div>}
              </div>

              <div className={styles.inputs}>
                {searchType == "Trainer" ? (
                  <Autocomplete
                    latlng={latlng}
                    mileRadius={mileRadius}
                    type={searchType}
                    handleSearch={handleSearch}
                    page={"burger"}
                    closeBurger={() => setOpen(false)}
                  />
                ) : (
                  <ClassesAutocomplete
                    page={"burger"}
                    handleSearch={handleClassSearch}
                    closeBurger={() => setOpen(false)}
                  />
                )}
                {searchType == "Trainer" && <div className={styles["input-container"]}>
                  <GoogleAutocomplete setLatLng={setLatLng} page={"burger"} />
                </div>}
              </div>
            </div>
            <hr />
          </div>
          
        )}

        

        {!loading && user &&
          ( 
            <>
              <div className={styles["mobile-item"]}>
                <NavLink to="account" onClick={() => setOpen(false)}>
                  <span>My Account</span>
                </NavLink>
              </div>

              <hr />
            </>
          )}

        {/* {loading && <></>} */}
        {!loading && user === null && (
          <div className={styles["mobile-item"]}>
            <NavLink to="account" onClick={() => setOpen(false)}>
              <span>Log in/Sign Up</span>
            </NavLink>
            <hr />
          </div>
        )}

        <div className={styles["contacts"]}>
          <a href="mailto:info.trainerrater@gmail.com" className={styles.icons}>
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
          <a
            href="https://www.instagram.com/trainer.rater/"
            target="_blank"
            className={styles.icons}
          >
            <FontAwesomeIcon
              style={{ fontSize: "20px", width: "20px" }}
              icon={faInstagram}
            />
          </a>
          <a href="https://twitter.com/trainerrater" target="_blank" className={styles.icons}>
            <FontAwesomeIcon icon={faXTwitter} />
          </a>
        </div>
      </StyledMenu>
    </BlackBackground>
  );
};

const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 2rem;
  height: 44px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  position: relative;
  float: left;
  margin: 0;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: #8d99ae;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(52deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-52deg)" : "rotate(0)")};
    }
  }
`;

const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger id="burger-button" open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

function BurgerMenu(props) {
  const buttonId = document.getElementById("burger-button");
  const [open, setOpen] = React.useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false), buttonId);
  return (
    <div>
      <Burger open={open} setOpen={setOpen} />
      <Menu
        user={props.user}
        loading={props.loading}
        myRef={node}
        open={open}
        setOpen={setOpen}
        handleSearch={props.handleSearch}
        handleClassSearch={props.handleClassSearch}
      />
    </div>
  );
}

const useOnClickOutside = (ref, handler, id) => {
  useEffect(() => {
    const listener = (event) => {
      if (
        ref.current == event.target ||
        ref.current.contains(event.target) ||
        document.getElementById("burger-button") == event.target ||
        document.getElementById("burger-button") == event.target.parentElement
      ) {
        return;
      } else {
        handler(event);
      }
    };
    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

export default BurgerMenu;
