import Login from "./../../pages/log-in/Login";
import Signup from "./../../pages/signup/Signup";
import ForgotPassword from "./../../pages/forgot/ForgotPassword";
import styles from "./NotSignedIn.module.scss";

function NotSignedIn() {
  return (
    <div className={styles["account-main"]}>
      <div className={styles.flex}>
        <div className={styles["flex-div"]}>
          <Login account={true}/>
        </div>
        <div className={styles["flex-div"]}>
          <Signup account={true}/>
        </div>
        <div className={styles["flex-div"]}>
          <ForgotPassword account={true}/>
        </div>
      </div>
    </div>
  );
}

export default NotSignedIn;
