import styles from "./../log-in/Login.module.scss";
import { useNavigate } from "react-router-dom";
import { deleteAccount, confirmWithGoogle } from "./../../firebase";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

function Delete(props) {
  document.title = "Trainer Rater | Delete Account";

  const navigate = useNavigate();

  const [values, setValues] = useState({
    password: "",
  });
  const [passwordShown, setPasswordShown] = useState(false);

  const [errors, setErrors] = useState({ username: "", password: "" });
  const [error, setError] = useState("");

  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  function handleChange(event, input) {
    setValues((values) => {
      return {
        ...values,
        [input]: event.target.value,
      };
    });
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const isDisabled =
    values.password !== "" &&
    errors.password === ""
      ? false
      : true;

      function checkErrors(input, value) {
        if (input == "password" && value.replace(/\s/g, "").length < 6) {
          setErrors((errors) => {
            return {
              ...errors,
              password: "Password must be at least 6 characters long",
            };
          });
        }
      }

  return (
    <div className={styles["main-div"]}>
      <h2 style={{ margin: props.modal && "0" }}>Confirm Account Deletion</h2>
      <center>
        <p>
          This action is irreversible! If you'd like your reviews to be deleted
          prior to deleting your account, delete them now.
        </p>
      </center>

      <div className={styles.password}>
        <input
          className={errors.password != "" ? styles.error : undefined}
          value={values.password}
          placeholder="Password"
          onClick={() => {
            setErrors((errors) => {
              return {
                ...errors,
                password: "",
              };
            });
            setError("");
          }}
          onChange={(event) => {
            handleChange(event, "password");
          }}
          onBlur={(event) => {
            checkErrors("password", event.target.value);
          }}
          type={!passwordShown ? "password" : "text"}
        />
        <FontAwesomeIcon
          className={styles.eye}
          onClick={togglePasswordVisiblity}
          icon={passwordShown ? faEyeSlash : faEye}
        />
      </div>

      <span className={styles["error-message"]}>
        {errors.password != "" ? (
          <>
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faCircleExclamation}
            />
            {errors.password}
          </>
        ) : (
          ""
        )}

        {error != "" ? (
          <>
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faCircleExclamation}
            />
            {error}
          </>
        ) : (
          ""
        )}
      </span>

      <button
        onClick={() => {
          const logReturn = function () {
            return deleteAccount(values.password, props.userInfo.docId);
          };
          logReturn().then((data) => {
            // console.log(data);
            if (data == true) {
              props.setIsOpen(false);
              navigate("./../");
            } else {
              console.log(data)
              return;
            }
          });
        }}
        className={styles["login-btn"] + " " + styles.delete}
        disabled={isDisabled}
      >
        Confirm Deletion
      </button>

      {/* <button
        className="gsi-material-button"
        onClick={() => {
          const logReturn = function () {
            return confirmWithGoogle();
          };
          logReturn().then((data) => {
            // console.log(data.message);
            if (data == true) {
              const logReturn = function () {
                return deleteAccount("", props.userInfo.docId);
              };
              logReturn().then((data) => {
                // console.log(data);
                if (data == true) {
                  props.setIsOpen(false);
                  navigate("./../");
                } else {
                  // console.log(data)
                  return;
                }
              })
            }
            else{
              // console.log(data)
            }
          });
        }}
        style={{marginBottom: "10px"}}
      >
        <div className="gsi-material-button-state"></div>
        <div className="gsi-material-button-content-wrapper">
          <div className="gsi-material-button-icon">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              style={{ display: "block" }}
            >
              <path
                fill="#EA4335"
                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
              ></path>
              <path
                fill="#4285F4"
                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
              ></path>
              <path
                fill="#FBBC05"
                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
              ></path>
              <path
                fill="#34A853"
                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
              ></path>
              <path fill="none" d="M0 0h48v48H0z"></path>
            </svg>
          </div>
          <span className="gsi-material-button-contents">
            Delete with Google
          </span>
        </div>
      </button> */}
    </div>
  );
}

export default Delete;
