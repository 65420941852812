import { db } from "../../firebase";
import {
  collection,
  getDoc,
  deleteDoc,
  doc,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import GymHeader from "../../components/gym/GymHeader";
import GymReview from "../../components/gym/GymReview";

import { useParams } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import styles from "./GymPage.module.scss";
import Dropdown from "../../components/common/dropdown/Dropdown";
import GoogleAutocomplete from "../../components/google-autocomplete/GoogleAutocomplete";
import getDistance from "geolib/es/getDistance";

function GymPage(props) {
  document.title = "Trainer Rater | Gym Page";

  const [classesData, setClassesData] = useState(null);
  const [reviews, setReviews] = useState(null);

  const [loading, setLoading] = useState(true);
  const [isSelected, setIsSelected] = useState("Newest");

  const [location, setLocation] = useState("");

  const [latlng, setLatLng] = useState({});
  const [mileRadius, setMileRadius] = useState(5);

  const { gymname } = useParams();

  useEffect(() => {
    const getData = async () => {
      let classes = []
      const q = query(
        collection(db, "classes"),
        where("gym", "==", gymname)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        classes.push({ ...doc.data(), id: doc.id });
      });
      setClassesData(classes)
    };

    const getReviews = async () => {
      let trainers = [];
      const trainersData = await getDocs(collection(db, "trainers"));
      trainersData.forEach((trainer) =>
        trainers.push({ ...trainer.data(), id: trainer.id })
      );

      let users = []
      const usersData = await getDocs(collection(db, "users"));
      usersData.forEach((user) =>
        users.push({ ...user.data(), id: user.id })
      );

      const reviewsRef = collection(db, "reviews");

      const q = query(reviewsRef, where("gym", "==", gymname));

      const querySnapshot = await getDocs(q);
      let reviewData = [];
      await querySnapshot.forEach((documentSnapshot) => {
  

              // doc.data() is never undefined for query doc snapshots
              // console.log(doc.id, " => ", doc.data());

              //   console.log(fetched.snapshot);
              //   console.log("Review: ", documentSnapshot.id, documentSnapshot.data());
              
                reviewData.push({
                    ...documentSnapshot.data(),
                    id: documentSnapshot.id,
                    user: users.filter(
                      (user) =>
                        user.email === documentSnapshot.data().user
                    )[0] || { name: "Deleted User"},
                    trainer: trainers.filter(
                      (trainer) =>
                        trainer.id === documentSnapshot.data().trainer
                    )[0],
                  })
                
            });
            console.log(reviewData)
            setReviews(reviewData.sort((a, b) => b.date - a.date));
            
        
  
    
      setLoading(false);
    };

    getData();
    getReviews();
  }, [gymname]);

  function handleSort(direction) {
    setIsSelected(direction);
    if (direction == "Oldest") {
      setReviews(reviews.sort((a, b) => a.date - b.date));
    } else if (direction == "Newest") {
      setReviews(reviews.sort((a, b) => b.date - a.date));
    } else if (direction == "Rating (Low - High)") {
      setReviews(reviews.sort((a, b) => a.rating - b.rating));
    } else if (direction == "Rating (High - Low)") {
      setReviews(reviews.sort((a, b) => b.rating - a.rating));
    }
  }

  async function confirmDelete(reviewID, review) {
  //   // function to delete post and remove it from list of reviews
  //   data.rating.splice(data.rating.indexOf(review.rating), 1);
  //   data.takeAgain.splice(data.takeAgain.indexOf(review.again), 1);

  //   const ref = doc(db, "classes", data.id);

  //   await updateDoc(ref, {
  //     rating: data.rating,
  //     takeAgain: data.takeAgain,
  //   }).then(
  //     setData({
  //       ...data,
  //       rating: data.rating,
  //       takeAgain: data.takeAgain,
  //     })
  //   );

  //   review.trainer.rating.splice(
  //     review.trainer.rating.indexOf(review.rating),
  //     1
  //   );
  //   review.trainer.takeAgain.splice(
  //     review.trainer.takeAgain.indexOf(review.again),
  //     1
  //   );

  //   const ref2 = doc(db, "trainers", review.trainer.id);

  //   await updateDoc(ref2, {
  //     rating: review.trainer.rating,
  //     takeAgain: review.trainer.takeAgain,
  //   });

  //   await deleteDoc(doc(db, "reviews", reviewID)).then(() =>
  //     setReviews(reviews.filter((review) => review.id !== reviewID))
  //   );
  }

  function checkDistance(item) {
    if (JSON.stringify(latlng) === "{}") {
      return true;
    }
    const distance =
      getDistance(
        { latitude: item.latlng.lat, longitude: item.latlng.lng },
        { latitude: latlng.lat, longitude: latlng.lng }
      ) * 0.00062137;
    return (Math.round(distance * 100) / 100).toFixed(2);
  }

  if (loading) {
    return (
      <div className={styles["trainer-profile-container"]}>
        <br />
        <center>
          <Bars
            height="80"
            width="80"
            color="#2B2D42"
            ariaLabel="bars-loading"
            wrapperStyle={{
              display: "block !important)",
            }}
            wrapperClass="bars"
            visible={true}
          />
        </center>
      </div>
    );
  } else {
    return (
      <div className={styles["trainer-profile-container"]}>
        <div className={styles["trainer-profile-flex"]}>
          {!loading && classesData && reviews && (
            <div className={styles.header}>
              <GymHeader gymname={gymname} data={classesData} />
            </div>
          )}
          <div className={styles.reviews}>
            <div className={styles["dropdown-container"]}>
              <Dropdown
                selected={isSelected}
                setIsSelected={setIsSelected}
                handleChange={handleSort}
                options={[
                  "Oldest",
                  "Newest",
                  "Rating (High - Low)",
                  "Rating (Low - High)",
                ]}
                page={"trainer"}
              />
            </div>
            <div className={styles["filter-div"]}>
              <span>Mile Radius: </span>
              <Dropdown
                selected={mileRadius}
                setIsSelected={setMileRadius}
                handleChange={() => {}}
                options={[5, 10, 25, 50]}
                page={"classes"}
              />
            </div>
            <div className={styles["input-container"]}>
              <GoogleAutocomplete
                setLocation={setLocation}
                setLatLng={setLatLng}
              />
            </div>

            <div className={styles["reviews-scroll"]}>
              {!loading &&
                classesData &&
                reviews &&
                reviews.length > 0 &&
                reviews
                  .filter((item) => checkDistance(item) - mileRadius < 0)

                  .map((review, index) => (
                    <GymReview
                      key={index}
                      review={review}
                      setIsOpen={props.setIsOpen}
                      confirmDelete={confirmDelete}
                    />
                  ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GymPage;
