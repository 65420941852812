import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { faSquare as faSquareHollow } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRefinementList } from "react-instantsearch";
import styles from "./SearchPage.module.scss";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import FilterMenu from "./FilterMenu";

function CustomRefinementList(props) {
  const {
    items,
    refine,
  } = useRefinementList(props);

  return (
    <>
      <FilterMenu>
        <div className={styles.drawer}>
        <h2 className={styles["filter-title"]}>Filters</h2>
        <Accordion
          disableGutters
          elevation={0}
          square
          sx={{ width: "100%", backgroundColor: "#EDF2F4", padding: "0 5px", boxSizing: "border-box" }}
        >
          <AccordionSummary
            expandIcon={
              <FontAwesomeIcon
                style={{ color: "#2B2D42" }}
                icon={faCaretDown}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ padding: 0, borderBottom: "1px solid #2B2D42" }}
          >
            <span className={styles["accordion-title"]}>Gym Type</span>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingLeft: 0 }}>
            {items?.map((item, index) => (
              <li key={index} className={styles.item} onClick={() => refine(item.value)}>
                <FontAwesomeIcon
                  icon={item.isRefined ? faCheckSquare : faSquareHollow}
                />{" "}
                {item.isRefined ? (
                  <b>
                    <span>{item.label}</span>
                  </b>
                ) : (
                  <span>{item.label}</span>
                )}{" "}
                <span className={styles.count}>{item.count}</span>
              </li>
            ))}
          </AccordionDetails>
        </Accordion>
        </div>
      </FilterMenu>
      <div className={styles["filter-desktop"]}>
        <h2 className={styles["filter-title"]}>Filters</h2>
        <Accordion
          disableGutters
          elevation={0}
          square
          sx={{ width: "100%", backgroundColor: "#EDF2F4" }}
        >
          <AccordionSummary
            expandIcon={
              <FontAwesomeIcon
                style={{ color: "#2B2D42" }}
                icon={faCaretDown}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ padding: 0, borderBottom: "1px solid #2B2D42" }}
          >
            <span className={styles["accordion-title"]}>Gym Type</span>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingLeft: 0 }}>
            {items?.map((item, index) => (
              <li key={index} className={styles.item} onClick={() => refine(item.value)}>
                <FontAwesomeIcon
                  icon={item.isRefined ? faCheckSquare : faSquareHollow}
                />{" "}
                {item.isRefined ? (
                  <b>
                    <span>{item.label}</span>
                  </b>
                ) : (
                  <span>{item.label}</span>
                )}{" "}
                <span className={styles.count}>{item.count}</span>
              </li>
            ))}
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}

export default CustomRefinementList;
