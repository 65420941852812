import { useNavigate } from "react-router-dom";
import styles from "./SearchPage.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDumbbell } from "@fortawesome/free-solid-svg-icons";

function Hit({ hit }) {
  function check(a, b) {
    return a + b;
  }

  const sum = hit.rating.length > 0 ? hit.rating.reduce(check) : 0;
  const avg = sum / hit.rating.length;

  const navigate = useNavigate();
  return (
    <div
      className={styles["trainer-item"]}
      onClick={() => navigate("../trainers/" + hit.objectID)}
    >
      <div className={styles.top}>
        <h2>{hit.name}</h2>

        <div className={styles["stars-mobile"]}>
          <span className={styles.rating}>
            {hit.rating !== 0 ? avg.toFixed(2) : "New"}
          </span>
          <span className={styles.star}>
            {hit.rating !== 0 && <FontAwesomeIcon icon={faDumbbell} />}
          </span>
          <span style={{ marginLeft: "5px" }}>({hit.rating.length})</span>
        </div>

        <div className={styles.content}>
          <span>{hit.city}</span>
        </div>

        <div>
          {hit.type.length > 1
            ? hit.type[0] + " + " + (hit.type.length - 1) + " more"
            : hit.type[0]}
        </div>
      </div>
    </div>
  );
}


export default Hit;
