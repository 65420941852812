import { useState } from "react";
import styles from "./StarSelector.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDumbbell } from "@fortawesome/free-solid-svg-icons";

function StarSelector(props) {
  const [stars, setStars] = useState([0, 0, 0, 0, 0]);
  const [rating, setRating] = useState(0);


  function setStarRating(index){
        if(rating == index + 1){
            setRating(0)
            props.handleChange("rating", 0)

        }
        else{
            setRating(index + 1)
            props.handleChange("rating", index + 1)
        }

    //   console.log(index)
    //   setStars(stars.map((star, index2) => index2 <= index ? 1 : 0))
  }
  return (
    <div>
      {stars.map((star, index) => (
        <div key={index} className={styles["star"]}>
          <span onClick={() => setStarRating(index)} className={styles["star-under"]}>
            <FontAwesomeIcon icon={faDumbbell} />
          </span>
          <span
            onClick={() => setStarRating(index)}
            style={{ width: index + 1 <= rating ? "100%" :  "0%" }}
            className={styles["star-over"]}
          >
            <FontAwesomeIcon icon={faDumbbell} />
          </span>
        </div>
      ))}
      {/* <span style={{ margin: "0 5px", fontSize: "50px" }}>•</span> */}
      <span className={styles.number}>{rating}/5</span>
    </div>
  );
}

export default StarSelector;
