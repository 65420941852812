import styles from "./RadioButton.module.scss";

function RadioButton(props) {
  return (
    <>
      <div className={styles.radiobutton}>
        <label>
          <input
            type="radio"
            value={props.value}
            name={props.stateToSet}
            onChange={(event) => {
              props.handleChange(props.stateToSet, props.value);
            }}
            checked={props.values[props.stateToSet] === props.value}
          />
          <span>{props.label}</span>
        </label>
        <br />
      </div>
    </>
  );
}

export default RadioButton;
