import styles from "./Login.module.scss";
import "./Login.scss";
import { useState } from "react";
import { logInWithEmailAndPassword, signInWithGoogle } from "../../firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

function Login(props) {
  document.title = "Trainer Rater | Log In";

  const navigate = useNavigate();
  const location = useLocation();

  const [values, setValues] = useState({
    username: "",
    password: "",
  });
  const [passwordShown, setPasswordShown] = useState(false);

  const [errors, setErrors] = useState({ username: "", password: "" });
  const [error, setError] = useState("");

  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  function handleChange(event, input) {
    setValues((values) => {
      return {
        ...values,
        [input]: event.target.value,
      };
    });
  }

  function checkErrors(input, value) {
    if (input == "password" && value.replace(/\s/g, "").length < 6) {
      setErrors((errors) => {
        return {
          ...errors,
          password: "Password must be at least 6 characters long",
        };
      });
    }
    if (input == "username" && value.replace(/\s/g, "").length < 1) {
      setErrors((errors) => {
        return {
          ...errors,
          username:
            value.replace(/\s/g, "").length < 1
              ? "Cannot leave field blank"
              : "",
        };
      });
    } else if (input == "username" && value.replace(/\s/g, "").length >= 1) {
      setErrors((errors) => {
        return {
          ...errors,
          username: validEmailRegex.test(value) ? "" : "Email is not valid!",
        };
      });
    }

    // setErrors(errors)
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const isDisabled =
    values.username !== "" &&
    values.password !== "" &&
    errors.username === "" &&
    errors.password === ""
      ? false
      : true;

  return (
    <div className={styles["main-div"]}>
      {!props?.modal && <h2>Sign In</h2>}
      {!props?.modal && <p>Sign in to interact with the community</p>}
      <div className={styles.email}>
        <input
          className={errors.username != "" ? styles.error : undefined}
          value={values.username}
          placeholder="Email"
          onClick={() => {
            setErrors((errors) => {
              return {
                ...errors,
                username: "",
              };
            });
            setError("");
          }}
          onChange={(event) => {
            handleChange(event, "username");
          }}
          onBlur={(event) => {
            checkErrors("username", event.target.value);
          }}
          type="text"
        />
      </div>

      <span className={styles["error-message"]}>
        {errors.username != "" ? (
          <>
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faCircleExclamation}
            />
            {errors.username}
          </>
        ) : (
          ""
        )}
      </span>

      <div className={styles.password}>
        <input
          className={errors.password != "" ? styles.error : undefined}
          value={values.password}
          placeholder="Password"
          onClick={() => {
            setErrors((errors) => {
              return {
                ...errors,
                password: "",
              };
            });
            setError("");
          }}
          onChange={(event) => {
            handleChange(event, "password");
          }}
          onBlur={(event) => {
            checkErrors("password", event.target.value);
          }}
          type={!passwordShown ? "password" : "text"}
        />
        <FontAwesomeIcon
          className={styles.eye}
          onClick={togglePasswordVisiblity}
          icon={passwordShown ? faEyeSlash : faEye}
        />
      </div>

      <span className={styles["error-message"]}>
        {errors.password != "" ? (
          <>
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faCircleExclamation}
            />
            {errors.password}
          </>
        ) : (
          ""
        )}

        {error != "" ? (
          <>
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faCircleExclamation}
            />
            {error}
          </>
        ) : (
          ""
        )}
      </span>

      <button
        onClick={() => {
          const logReturn = function () {
            return logInWithEmailAndPassword(values.username, values.password);
          };
          logReturn().then((data) => {
            // console.log(data.message);
            if (data == true) {
              props.modal && props.setIsOpen(false);
              !props?.modal && navigate("/");
            } else if (
              data.message == "Firebase: Error (auth/invalid-email)."
            ) {
              setError("Invalid email");
              return;
            } else if (
              data.message == "Firebase: Error (auth/wrong-password)." ||
              data.message == "Firebase: Error (auth/internal-error)."
            ) {
              setError("Wrong password");
              return;
            } else if (
              data.message == "Firebase: Error (auth/user-not-found)."
            ) {
              setError("No user found with the given email address");
              return;
            }
          });
        }}
        disabled={isDisabled}
        className={styles["login-btn"]}
      >
        Sign In
      </button>

      {/* <button
        className="gsi-material-button"
        onClick={() => {
          const logReturn = function () {
            return signInWithGoogle();
          };
          logReturn().then((data) => {
            // console.log(data.message);
            if (data == true) {
              // props.modal && window.location.reload()
              
              // !props?.modal && navigate("/");
              // !props?.modal && window.location.reload()

            }
            else{
              console.log(data)
            }
          });
        }}
        style={{marginBottom: "10px"}}
      >
        <div className="gsi-material-button-state"></div>
        <div className="gsi-material-button-content-wrapper">
          <div className="gsi-material-button-icon">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              style={{ display: "block" }}
            >
              <path
                fill="#EA4335"
                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
              ></path>
              <path
                fill="#4285F4"
                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
              ></path>
              <path
                fill="#FBBC05"
                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
              ></path>
              <path
                fill="#34A853"
                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
              ></path>
              <path fill="none" d="M0 0h48v48H0z"></path>
            </svg>
          </div>
          <span className="gsi-material-button-contents">
            Sign in with Google
          </span>
          <span style={{ display: "none" }}>Sign in with Google</span>
        </div>
      </button> */}

      {/* <p>
        Don't have an account?{" "}
        <a
          onClick={() =>
            !props.modal ? navigate("/register") : props.setActive("Signup")
          }
        >
          Join Us!
        </a>
      </p> */}

      {!props?.account && (
        <a onClick={() => (!props?.modal ? "" : props.setActive("Forgot"))}>
          Forgot Password?
        </a>
      )}

      {/*
      <p>
        <a onClick={() => navigate("/forgot")}>Forgot Password?</a>
      </p> */}
    </div>
  );
}

export default Login;
