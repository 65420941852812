import styles from "./TrainerReview.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as FaHeartRegular } from "@fortawesome/free-regular-svg-icons";

import { useContext, useEffect, useState } from "react";
import { AuthenticatedUserContext } from "../../providers/AuthenticatedUserProvider";

function LikeButton(props) {
  const { userInfo } = useContext(AuthenticatedUserContext);
  // console.log(userInfo);
  const [liked, setLiked] = useState(
    props.data.likes.indexOf(userInfo?.email) !== -1 ? true : false
  );

  useEffect(() => {
    setLiked(props.data.likes.indexOf(userInfo?.email) !== -1 ? true : false)
  }, [JSON.stringify(props.data)])
  function likePost() {
    if (userInfo == null) {
      props.likePost(null);
    } else {
      props.likePost(userInfo.email);
      setLiked(!liked);
    }
  }

  return (
    <div className={styles.like}>
      <span onClick={likePost}>
        <FontAwesomeIcon
          className={liked ? styles["red"] : ""}
          style={{ marginRight: "5px" }}
          icon={liked ? faHeart : FaHeartRegular}
        />
        {props.data.likes.length}
      </span>
    </div>
  );
}

export default LikeButton;
