import styles from "./PrivacyPolicy.module.scss";

function PrivacyPolicy() {
  document.title = "Trainer Rater | Privacy Policy";

  return (
    <div className={styles.container}>
      <h1>Privacy Policy</h1>
      <span>Last updated: December 28, 2023</span>

      <p>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </p>

      <p>
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </p>

      <h1>Interpretation and Definitions</h1>
      <h2>Interpretation</h2>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>

      <h2>Definitions</h2>
      <p>For the purposes of this Privacy Policy:</p>

      <ul>
        <li>
          <b>Account</b> means a unique account created for You to access our
          Service or parts of our Service.
        </li>
        <li>
          <b>Company</b> (referred to as either "the Company", "We", "Us" or
          "Our" in this Agreement) refers to Trainer Rater.
        </li>
        <li>
          <b>Cookies</b> are small files that are placed on Your computer,
          mobile device or any other device by a website, containing the details
          of Your browsing history on that website among its many uses.
        </li>
        <li>
          <b>Device</b> means any device that can access the Service such as a
          computer, a cellphone or a digital tablet.
        </li>
        <li>
          <b>Personal Data</b> is any information that relates to an identified
          or identifiable individual.
        </li>
        <li>
          <b>Service</b> refers to the Website.
        </li>
        <li>
          <b>Service Provider</b> means any natural or legal person who
          processes the data on behalf of the Company. It refers to third-party
          companies or individuals employed by the Company to facilitate the
          Service, to provide the Service on behalf of the Company, to perform
          services related to the Service or to assist the Company in analyzing
          how the Service is used.
        </li>
        <li>
          <b>Usage Data</b> refers to data collected automatically, either
          generated by the use of the Service or from the Service infrastructure
          itself (for example, the duration of a page visit).
        </li>
        <li>
          <b>Website</b> refers to Trainer Rater, accessible from
          https://www.trainer-rater.com
        </li>
        <li>
          You means the individual accessing or using the Service, or the
          company, or other legal entity on behalf of which such individual is
          accessing or using the Service, as applicable.
        </li>
      </ul>

      <h1>Collecting and Using Your Personal Data</h1>
      <h2>Types of Data Collected</h2>
      <h3>Personal Data</h3>
      <p>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </p>

      <ul>
        <li>Email Address</li>
        <li>First Name and Last Name</li>
        <li>Usage Data</li>
        <li>Current Location</li>
      </ul>

      <h3>Usage Data</h3>
      <p>Usage Data is collected automatically when using the Service.</p>

      <p>
        Usage Data may include information such as Your Device's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </p>

      <p>
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </p>

      <p>
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </p>

      <h3>Use of Your Personal Data</h3>
      <p>The Company may use Personal Data for the following purposes:</p>

      <p>
        To provide and maintain our Service, including to monitor the usage of
        our Service.
      </p>
      <p>
        To manage Your Account: to manage Your registration as a user of the
        Service. The Personal Data You provide can give You access to different
        functionalities of the Service that are available to You as a registered
        user.
      </p>
      <p>
        To contact You: To contact You by email, or other equivalent forms of
        electronic communication, such as a mobile application's push
        notifications regarding updates or informative communications related to
        the functionalities, products or contracted services, including the
        security updates, when necessary or reasonable for their implementation.
      </p>
      <p>
        To provide You with news, special offers and general information about
        other goods, services and events which we offer that are similar to
        those that you have already purchased or enquired about unless You have
        opted not to receive such information.
      </p>
      <p>To manage Your requests: To attend and manage Your requests to Us.</p>
      <p>
        For business transfers: We may use Your information to evaluate or
        conduct a merger, divestiture, restructuring, reorganization,
        dissolution, or other sale or transfer of some or all of Our assets,
        whether as a going concern or as part of bankruptcy, liquidation, or
        similar proceeding, in which Personal Data held by Us about our Service
        users is among the assets transferred.
      </p>
      <p>
        For other purposes: We may use Your information for other purposes, such
        as data analysis, identifying usage trends, determining the
        effectiveness of our promotional campaigns and to evaluate and improve
        our Service, products, services, marketing and your experience.
      </p>
      <p>We may share Your personal information in the following situations:</p>

      <ul>
        <li>
          With Service Providers: We may share Your personal information with
          Service Providers to monitor and analyze the use of our Service, to
          contact You.
        </li>
        <li>
          For business transfers: We may share or transfer Your personal
          information in connection with, or during negotiations of, any merger,
          sale of Company assets, financing, or acquisition of all or a portion
          of Our business to another company.
        </li>
        <li>
          With Affiliates: We may share Your information with Our affiliates, in
          which case we will require those affiliates to honor this Privacy
          Policy. Affiliates include Our parent company and any other
          subsidiaries, joint venture partners or other companies that We
          control or that are under common control with Us.
        </li>
        <li>
          With business partners: We may share Your information with Our
          business partners to offer You certain products, services or
          promotions.
        </li>
        <li>
          With other users: when You share personal information or otherwise
          interact in the public areas with other users, such information may be
          viewed by all users and may be publicly distributed outside.
        </li>
        <li>
          With Your consent: We may disclose Your personal information for any
          other purpose with Your consent.
        </li>
      </ul>

      <h2>Retention of Your Personal Data</h2>
      <p>
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.
      </p>

      <p>
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </p>

      <h2>Transfer of Your Personal Data</h2>
      <p>
        Your information, including Personal Data, is processed at the Company's
        operating offices and in any other places where the parties involved in
        the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from Your jurisdiction.
      </p>

      <p>
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </p>

      <p>
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </p>

      <h2>Disclosure of Your Personal Data</h2>
      <h3>Business Transactions</h3>
      <p>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </p>

      <h3>Law enforcement</h3>
      <p>
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </p>

      <h3>Other legal requirements</h3>
      <p>
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
      </p>

      <ul>
        <li>Comply with a legal obligation</li>
        <li>Protect and defend the rights or property of the Company</li>
        <li>
          Prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li>
          Protect the personal safety of Users of the Service or the public
        </li>
        <li>Protect against legal liability</li>
      </ul>

      <h2>Security of Your Personal Data</h2>
      <p>
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </p>

      <h1>Detailed Information on the Processing of Your Personal Data</h1>
      <p>
        The Service Providers We use may have access to Your Personal Data.
        These third-party vendors collect, store, use, process and transfer
        information about Your activity on Our Service in accordance with their
        Privacy Policies.
      </p>

      <h2>Analytics</h2>
      <p>
        We may use third-party Service providers to monitor and analyze the use
        of our Service.
      </p>

      <h3>Google Analytics</h3>
      <p>
        Google Analytics is a web analytics service offered by Google that
        tracks and reports website traffic. Google uses the data collected to
        track and monitor the use of our Service. This data is shared with other
        Google services. Google may use the collected data to contextualize and
        personalize the ads of its own advertising network.
      </p>

      <p>
        You can opt-out of having made your activity on the Service available to
        Google Analytics by installing the Google Analytics opt-out browser
        add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
        analytics.js and dc.js) from sharing information with Google Analytics
        about visits activity.
      </p>

      <p>
        For more information on the privacy practices of Google, please visit
        the Google Privacy and Terms web page:
        https://policies.google.com/privacy
      </p>

      <h2>Limited Use Requirements</h2>
      <p>
        Trainer Rater's use and transfer to any other app of information received
        from Google APIs will adhere to Google API Services User Data Policy,
        including the Limited Use requirements.”
      </p>

      <h1>Account Information</h1>
      <p>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:
      </p>

      <ul>
        <li>Log in to your account settings and update your user account.</li>
      </ul>

      <p>
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        we may retain some information in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our legal
        terms and/or comply with applicable legal requirements.
      </p>

      <p>
        If you have questions or comments about your privacy rights, you may
        email us at info.trainerrater.com
      </p>

      <h2>California Residents</h2>

      <p>
        California Civil Code Section 1798.83, also known as the "Shine The
        Light" law permits our users who are California residents to request and
        obtain from us, once a year and free of charge, information about
        categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>

      <p>
        If you are under 18 years of age, reside in California, and have a
        registered account with the Services, you have the right to request
        removal of unwanted data that you publicly post on the Services. To
        request removal of such data, please contact us using the contact
        information provided below and include the email address associated with
        your account and a statement that you reside in California. We will make
        sure the data is not publicly displayed on the Services, but please be
        aware that the data may not be completely or comprehensively removed
        from all our systems (e.g., backups, etc.).
      </p>

      <p>
        <b>
          <u>CCPA Privacy Notice</u>
        </b>
      </p>

      <p>
        This section applies only to California residents. Under the California
        Consumer Privacy Act (CCPA), you have the rights listed below.
      </p>
      <p>The California Code of Regulations defines a "residents" as:</p>

      <p>
        (1) every individual who is in the State of California for other than a
        temporary or transitory purpose and
      </p>
      <p>
        (2) every individual who is domiciled in the State of California who is
        outside the State of California for a temporary or transitory purpose
      </p>

      <p>All other individuals are defined as "non-residents."</p>
      <p>
        If this definition of "resident" applies to you, we must adhere to
        certain rights and obligations regarding your personal information.
      </p>

      <p>
        <strong>Your rights with respect to your personal data</strong>
      </p>

      <p>
        <u>Right to request deletion of the data — Request to delete</u>
      </p>
      <p>
        You can ask for the deletion of your personal information. If you ask us
        to delete your personal information, we will respect your request and
        delete your personal information, subject to certain exceptions provided
        by law, such as (but not limited to) the exercise by another consumer of
        his or her right to free speech, our compliance requirements resulting
        from a legal obligation, or any processing that may be required to
        protect against illegal activities.
      </p>
      <p>
        <u>Right to be informed — Request to know</u>
      </p>
      <p>Depending on the circumstances, you have a right to know:</p>

      <ul>
        <li>whether we collect and use your personal information;</li>
        <li>the categories of personal information that we collect;</li>
        <li>
          the purposes for which the collected personal information is used;
        </li>
        <li>whether we sell or share personal information to third parties;</li>
        <li>
          the categories of personal information that we sold, shared, or
          disclosed for a business purpose;
        </li>
        <li>
          the categories of third parties to whom the personal information was
          sold, shared, or disclosed for a business purpose;
        </li>
        <li>
          the business or commercial purpose for collecting, selling, or sharing
          personal information; and
        </li>
        <li>
          the specific pieces of personal information we collected about you.
        </li>
      </ul>

      <p>
        In accordance with applicable law, we are not obligated to provide or
        delete consumer information that is de-identified in response to a
        consumer request or to re-identify individual data to verify a consumer
        request.
      </p>

      <p>
        <u>
          Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
          Rights
        </u>
      </p>
      <p>
        We will not discriminate against you if you exercise your privacy
        rights.
      </p>
      <p>
        <u>
          Right to Limit Use and Disclosure of Sensitive Personal Information
        </u>
      </p>
      <p>We do not process consumer's sensitive personal information.</p>

      <p>
        <u>Verification process</u>
      </p>

      <p>
        Upon receiving your request, we will need to verify your identity to
        determine you are the same person about whom we have the information in
        our system. These verification efforts require us to ask you to provide
        information so that we can match it with information you have previously
        provided us. For instance, depending on the type of request you submit,
        we may ask you to provide certain information so that we can match the
        information you provide with the information we already have on file, or
        we may contact you through a communication method (e.g., phone or email)
        that you have previously provided to us. We may also use other
        verification methods as the circumstances dictate.
      </p>

      <p>
        We will only use personal information provided in your request to verify
        your identity or authority to make the request. To the extent possible,
        we will avoid requesting additional information from you for the
        purposes of verification. However, if we cannot verify your identity
        from the information already maintained by us, we may request that you
        provide additional information for the purposes of verifying your
        identity and for security or fraud-prevention purposes. We will delete
        such additionally provided information as soon as we finish verifying
        you.
      </p>

      <p>
        <u>Other privacy rights</u>
      </p>

      <ul>
        <li>You may object to the processing of your personal information.</li>

        <li>
          You may request correction of your personal data if it is incorrect or
          no longer relevant, or ask to restrict the processing of the
          information.
        </li>

        <li>
          You can designate an authorized agent to make a request under the CCPA
          on your behalf. We may deny a request from an authorized agent that
          does not submit proof that they have been validly authorized to act on
          your behalf in accordance with the CCPA.
        </li>

        <li>
          You may request to opt out from future selling or sharing of your
          personal information to third parties. Upon receiving an opt-out
          request, we will act upon the request as soon as feasibly possible,
          but no later than fifteen (15) days from the date of the request
          submission.
        </li>
      </ul>

      <p>
        To exercise these rights, you can contact us by submitting a data
        subject access request, by email at info.trainerrater@gmail.com, or by
        referring to the contact details at the bottom of this document. If you
        have a complaint about how we handle your data, we would like to hear
        from you.
      </p>

      <h1>Links to Other Websites</h1>
      <p>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party's site. We strongly advise You to review the Privacy Policy
        of every site You visit.
      </p>

      <p>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>

      <h1>Changes to this Privacy Policy</h1>
      <p>
        We may update Our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
      </p>

      <p>
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the "Last updated"
        date at the top of this Privacy Policy.
      </p>

      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>

      <h1>Contact Us</h1>
      <p>
        If you have any questions about this Privacy Policy, You can contact us
        by email: info.trainerrater@gmail.com
      </p>
    </div>
  );
}

export default PrivacyPolicy;
