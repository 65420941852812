import styles from "./Footer.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { faInstagram, faXTwitter } from "@fortawesome/free-brands-svg-icons";

function Footer() {
  const navigate = useNavigate();
  return (
    <footer>
      <div className={styles["footer-grid"]}>
        <div className={styles["grid-item"]}>
          <h4>INFO</h4>
          <span className={styles["left-link"]} onClick={() => navigate("./../../#about")}>About</span>
          <span className={styles["left-link"]} onClick={() => navigate("./../../announcements")}>Announcements</span>
          <span className={styles["left-link"]} onClick={() => navigate("./../../#how-it-works")}>How It Works</span>
          <span className={styles["left-link"]} onClick={() => navigate("./../../team")}>The Team</span>
          <span className={styles["left-link"]} onClick={() => navigate("./../../privacy")}>Privacy Policy</span>
          <span className={styles["left-link"]} onClick={() => navigate("./../../terms-and-conditions")}>Terms and Conditions</span>
        </div>

        <div className={styles["grid-item"]}>
          <h4>CONTACT</h4>
          <a href="mailto:info.trainerrater@gmail.com" className={styles["right-link"]}><FontAwesomeIcon style={{width: "20px"}} icon={faEnvelope} /> <span>info.trainerrater@gmail.com</span></a>
          <a href="https://www.instagram.com/trainer.rater/" target="_blank" className={styles["right-link"]}><FontAwesomeIcon style={{fontSize: "20px", width: "20px"}} icon={faInstagram} /> <span>@trainer.rater</span></a>
          <a href="https://twitter.com/trainerrater" target="_blank" className={styles["right-link"]}><FontAwesomeIcon style={{fontSize: "20px", width: "20px"}} icon={faXTwitter} /> <span>@trainerrater</span></a>

        </div>

        {/* <div className={styles["grid-item"]}>
          <img src="/main-logo.png"/>
        </div> */}
      </div>
    </footer>
  );
}

export default Footer;
