import React, {useState, createContext} from 'react';

// import firestore from '@react-native-firebase/firestore';

export const AuthenticatedUserContext = createContext({});

export const AuthenticatedUserProvider = ({children}) => {
//   const [user, setUser] = useState(null);

  const [userInfo, setUserInfo] = useState(null);

  return (
    <AuthenticatedUserContext.Provider
      value={{userInfo, setUserInfo}}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
};