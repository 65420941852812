import styles from "./../log-in/Login.module.scss";
import { useState } from "react";
import { logInWithEmailAndPassword, sendPasswordReset } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

function ForgotPassword(props) {
  document.title = "Trainer Rater | Forgot";

  const navigate = useNavigate();

  const [emailSent, setEmailSent] = useState(false);

  const [values, setValues] = useState({
    username: props?.email || "",
    password: "",
  });

  const [errors, setErrors] = useState({ username: "" });
  const [error, setError] = useState("");

  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  function handleChange(event, input) {
    setValues((values) => {
      return {
        ...values,
        [input]: event.target.value,
      };
    });
  }

  function checkErrors(input, value) {
    if (input == "username" && value.replace(/\s/g, "").length < 1) {
      setErrors((errors) => {
        return {
          ...errors,
          username:
            value.replace(/\s/g, "").length < 1
              ? "Cannot leave field blank"
              : "",
        };
      });
    } else if (input == "username" && value.replace(/\s/g, "").length >= 1) {
      setErrors((errors) => {
        return {
          ...errors,
          username: validEmailRegex.test(value) ? "" : "Email is not valid!",
        };
      });
    }
  }

  if (!emailSent) {
    return (
      <div className={styles["main-div"]}>
        <h2 style={{ margin: props.modal && "0" }}>Forgot Password?</h2>
        <center>
          <p>
            Please enter your email address to receive a link to reset your
            password.
          </p>
        </center>
        <div className={styles.email}>
          <input
            className={errors.username != "" ? styles.error : undefined}
            value={values.username}
            placeholder="Email"
            onClick={() => {
              setErrors((errors) => {
                return {
                  ...errors,
                  username: "",
                };
              });
              setError("");
            }}
            onChange={(event) => {
              handleChange(event, "username");
            }}
            onBlur={(event) => {
              checkErrors("username", event.target.value);
            }}
            type="text"
          />
        </div>

        <span className={styles["error-message"]}>
          {errors.username != "" ? (
            <>
              <FontAwesomeIcon
                style={{ marginRight: "5px" }}
                icon={faCircleExclamation}
              />
              {errors.username}
            </>
          ) : (
            ""
          )}

          {error != "" ? (
            <>
              <FontAwesomeIcon
                style={{ marginRight: "5px" }}
                icon={faCircleExclamation}
              />
              {error}
            </>
          ) : (
            ""
          )}
        </span>

        <button
          onClick={() => {
            const logReturn = function () {
              return sendPasswordReset(values.username);
            };
            logReturn().then((data) => {
              // console.log(data);
              if (data == true) {
                !props?.modal && setEmailSent(true);
                props.modal && props.setIsOpen(false);
              } else {
                setError(data);
                return;
              }
            });
          }}
          disabled={
            errors.username === "" && values.username !== "" ? false : true
          }
          className={styles["login-btn"]}
        >
          Reset Password
        </button>

        {!props?.account && (
          <p>
            Don't have an account?{" "}
            <a
              onClick={() => {
                props?.modal && props.setActive("Register");
                props?.modal && props.setSelectedTab("Register");
              }}
            >
              Join Us!
            </a>
          </p>
        )}
      </div>
    );
  } else {
    return (
      <div className={styles["main-div"]}>
        <h2 style={{ margin: props.modal && "0" }}>Reset Email Sent!</h2>
        <center>
          <p>
            You should have received an email with a link to reset your
            password. Keep in mind the link expires after about 1 hour,
            otherwise you will have to{" "}
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => setEmailSent(false)}
            >
              submit the form again.
            </span>
          </p>
        </center>
      </div>
    );
  }
}

export default ForgotPassword;
