import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { useState, useEffect } from "react";
import styles from "./../log-in/Login.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword } from "./../../firebase";

function ResetPassword() {
  document.title = "Trainer Rater | Reset";

  let location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  // const mode = params.get("mode");
  const actionCode = params.get("oobCode");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    
    checkValidLink(auth, actionCode);
    
  }, []);

  const [values, setValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState("");
  const [validCode, setValidCode] = useState(false);

  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  function handleChange(event, input) {
    // checkErrors(input, event.target.value)
    setValues((values) => {
      return {
        ...values,
        [input]: event.target.value,
      };
    });
  }

  function checkErrors(input, value) {
    if (input == "password") {
      setErrors((errors) => {
        return {
          ...errors,
          password:
            value.replace(/\s/g, "").length < 6
              ? "Password must be at least 6 characters long"
              : "",
        };
      });
    }
    if (input == "confirmPassword") {
      setErrors((errors) => {
        return {
          ...errors,
          confirmPassword:
            value.replace(/\s/g, "").length < 6
              ? "Password must be at least 6 characters long"
              : "",
        };
      });
    }

    // setErrors(errors)
  }

  function checkValidLink(auth, code) {
    verifyPasswordResetCode(auth, code)
      .then((email) => {
        setValues((values) => {
          return {
            ...values,
            email: email,
          };
        });
        setValidCode(true);
      })
      .catch((error) => {
        // console.log(error);
        setValidCode(false);
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
      });
      setLoading(false)
  }

  function handleResetPassword(auth, actionCode) {
    // Verify the password reset code is valid.

    // const accountEmail = email;

    // TODO: Show the reset screen with the user's email and ask the user for
    // the new password.
    // const newPassword = "...";

    // Save the new password.
    confirmPasswordReset(auth, actionCode, values.password)
      .then((resp) => {
        // console.log(resp);
        // console.log("RESET");

        logInWithEmailAndPassword(values.email, values.password).then(navigate("/account"));
        // Password reset has been confirmed and new password updated.
        // TODO: Display a link back to the app, or sign-in the user directly
        // if the page belongs to the same domain as the app:
        // auth.signInWithEmailAndPassword(accountEmail, newPassword);
        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      })
      .catch((error) => {
        // console.log(error);
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
      });
  }

  if(!loading && validCode) {

   return (
    <div className={styles["main-div"]}>
      <h2>Reset Your Password</h2>
      <span>Resetting password for: {values.email}</span>
      <div className={styles.password}>
        <input
          className={errors.password != "" ? styles.error : undefined}
          onClick={() =>
            setErrors((errors) => {
              return {
                ...errors,
                password: "",
              };
            })
          }
          placeholder="Enter Your Password"
          onChange={(event) => handleChange(event, "password")}
          onBlur={(event) => {
            checkErrors("password", event.target.value);
          }}
          type={!passwordShown ? "password" : "text"}
          value={values.password}
        />
        <FontAwesomeIcon
          className={styles.eye}
          onClick={togglePasswordVisiblity}
          icon={passwordShown ? faEyeSlash : faEye}
        />
      </div>

      <span className={styles["error-message"]}>
        {errors.password != "" ? (
          <>
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faCircleExclamation}
            />
            {errors.password}
          </>
        ) : (
          ""
        )}
      </span>

      <div className={styles.password}>
        <input
          className={errors.confirmPassword != "" ? styles.error : undefined}
          onClick={() =>
            setErrors((errors) => {
              return {
                ...errors,
                confirmPassword: "",
              };
            })
          }
          placeholder="Confirm Your Password"
          onChange={(event) => handleChange(event, "confirmPassword")}
          type={!confirmPasswordShown ? "password" : "text"}
          value={values.confirmPassword}
          onBlur={(event) => {
            checkErrors("confirmPassword", event.target.value);
          }}
        />

        <FontAwesomeIcon
          className={styles.eye}
          onClick={toggleConfirmPasswordVisiblity}
          icon={confirmPasswordShown ? faEyeSlash : faEye}
        />
      </div>

      <span className={styles["error-message"]}>
        {errors.confirmPassword != "" ? (
          <>
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faCircleExclamation}
            />
            {errors.confirmPassword}
          </>
        ) : (
          ""
        )}

        {error != "" ? (
          <>
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faCircleExclamation}
            />
            {error}
          </>
        ) : (
          ""
        )}
      </span>

      <button
        onClick={() => {
          if (values.password !== values.confirmPassword) {
            setError("Passwords don't match");
          } else {
            handleResetPassword(auth, actionCode);
          }
        }}
        // disabled={isDisabe}
        className={styles["login-btn"]}
      >
        Reset Password
      </button>
    </div>
  );
      }
      else if(!loading && !validCode){
        return (
          <div className={styles["main-div"]}>
            <h2>Invalid code!</h2>
            <span className={styles["reset-span"]}>This can be because you took too long since you requested the reset.</span>
            <button className={styles["login-btn"]} onClick={() => navigate("/account")}>Click here to request again</button>
          </div>
        )
      }

      else{
        return (
          <div>
           
          </div>
        )
      }
}

export default ResetPassword;
