import Downshift from "downshift";
import styles from "./ReviewForm.module.scss";

import { db } from "../../firebase";
import {
  collection,
  getDoc,
  doc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { useEffect, useState, useContext } from "react";
import StarSelector from "./StarSelector";
import RadioButton from "../common/radio-button/RadioButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import MultiSelect from "./MultiSelect";
import GoogleAutocomplete from "./../google-autocomplete/GoogleAutocomplete";
import { AuthenticatedUserContext } from "./../../providers/AuthenticatedUserProvider";
import { Autocomplete } from "./../algolia/Autocomplete";
import { ClassesAutocomplete } from "./../algolia/ClassesAutocomplete";
import { Tooltip as ReactTooltip } from "react-tooltip";

function ReviewForm(props) {
  const { userInfo, setUserInfo } = useContext(AuthenticatedUserContext);

  const [trainers, setTrainers] = useState([]);
  const [type, setType] = useState([]);

  const [userReviews, setUserReviews] = useState([]);

  const [loading, setLoading] = useState(false);

  const params = new URL(document.location).searchParams;
  const trainer = params.get("trainer");
  const classname = params.get("class");

  const presetInput = trainer !== null ? trainer : classname;
  const presetType = trainer !== null ? "Trainer" : "Class";

  function check(a, b) {
    return a + b;
  }

  const isDisabled =
    props.values.type !== "" &&
    (props.values.classname !== "" || props.values["add-class"] !== "") &&
    props.values.comments !== "" &&
    props.values.rating !== "" &&
    props.values["go-again"] !== null &&
    (props.values.trainer !== "" ||
      (props.newTrainer != "" && props.trainerLatlng !== {})) &&
    props.location !== "" &&
    props.latlng !== {};

  // const isDisabled = true;

  // console.log(isDisabled);

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      let trainersArray = [];
      const data = await getDocs(collection(db, "trainers"));
      data.forEach((doc) =>
        trainersArray.push({ id: doc.id, data: doc.data() })
      );

      //   setTrainers(trainersArray);

      let reviewsArray = [];
      const reviewData = await getDocs(collection(db, "reviews"));
      reviewData.forEach((doc) =>
        reviewsArray.push({ id: doc.id, data: doc.data() })
      );

      let mergedArray = trainersArray.map((trainer) => {
        return {
          ...trainer,
          rating: reviewsArray
            .filter((review) => review.data.trainer == trainer.id)
            .map((review) => review.data.rating),
        };
      });

      //   mergedArray
      setTrainers(mergedArray);

      setType([
        ...new Set(mergedArray.map((review) => review.data.type).flat(1)),
      ]);
      // console.log(reviewsArray)
      setUserReviews(
        reviewsArray.filter((review) => review.data.user === userInfo?.id)
      );
    };
    getData();
    // getReviews();
  }, []);

  function getStars(array) {
    //   console.log(props.data.rating);
    // Reducing the numbers of the array
    let avg = 0;

    if (array.length > 0) {
      var sum = array.reduce(check);

      // Calculating the average of the numbers
      avg = sum / array.length;
    }

    const stars = [0, 0, 0, 0, 0].map((item, index) => {
      if (avg / (index + 1) >= 1) {
        return 1;
      } else if (
        avg / (index + 1) < 1 &&
        avg % (index + 1 > 0) &&
        index + 1 - avg < 1
      ) {
        return avg % (index + 1 > 0);
      } else {
        return 0;
      }
    });

    return stars;
  }

  async function submitReview() {
    props.submitReview(userReviews);
  }

  return (
    <div className={styles["main-div"]}>

        <div className={styles["trainer-dropdown-container"]}>
          <label>Name of Class</label>
          <ClassesAutocomplete
            latlng={{}}
            page={"form"}
            handleChange={props.handleChange}
            presetInput={presetInput}
            presetType={presetType}
            setAddClass={props.setAddClass}
          />
        </div>
     

      {props.error != "" && (
        <span className={styles["error-span"]}>{props.error}</span>
      )}

      <Downshift
        // onChange={(selection) => props.handleChange("type", selection)}
        onStateChange={({ inputValue }) => {
          return inputValue && props.handleChange("type", inputValue);
        }}
        selectedItem={props.values.type}
        itemToString={(item) => (item ? item : "")}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          getRootProps,
        }) => (
          <div className={styles["input-container"]}>
            <label {...getLabelProps()}>Gym</label>

            <input
              onChange={(event) =>
                props.handleChange("type", event.target.value)
              }
              value={props.values.type}
              {...getInputProps()}
            />

            <ul className={styles.dropdown} {...getMenuProps()}>
              {isOpen
                ? type
                    .filter(
                      (item) =>
                        !inputValue ||
                        item.toLowerCase().includes(inputValue.toLowerCase())
                    )
                    .map((item, index) => (
                      <li
                        className={styles.item}
                        key={index}
                        {...getItemProps({
                          key: item,
                          index,
                          item,
                        })}
                      >
                        {item}
                      </li>
                    ))
                : null}
            </ul>
          </div>
        )}
      </Downshift>

      <div className={styles["input-container"]}>
        <label>Location</label>
        <GoogleAutocomplete
          setLocation={props.setLocation}
          setLatLng={props.setLatLng}
        />
      </div>


        <div className={styles["trainer-dropdown-container"]}>
          <label>Trainer</label>
          <Autocomplete
            latlng={{}}
            page={"form"}
            handleChange={props.handleChange}
            presetInput={presetInput}
            presetType={presetType}
            setNewTrainer={props.setNewTrainer}
            setAddTrainer={props.setAddTrainer}
          />
        </div>

      {props.addTrainer == true && (

          <div className={styles["input-container"]}>
            <label>Trainer Location</label>
            <GoogleAutocomplete
              setLocation={props.setTrainerLocation}
              setLatLng={props.setTrainerLatLng}
            />
          </div>

      )}

      <div className={styles["multi-select-container"]}>
        <label>Type of Class</label>
        <MultiSelect
          options={[
            "Yoga",
            "Weights",
            "Cardio",
            "Pilates",
            "Full Body",
            "Upper Body",
            "Lower Body",
            "Bootcamp",
            "HIIT",
            "Cycling",
            "Calisthenics",
            "Sculpt",
            "Treadmill",
            "Dance",
            "Tabata",
            "Martial Arts"
          ]}
          selected={props.multiSelected}
          toggleOption={props.toggleOption}
        />
      </div>

      <div className={styles["stars-div"]}>
        <p>Rating</p>
        <StarSelector handleChange={props.handleChange} />
      </div>

      <div className={styles["textarea-container"]}>
        <label>Comments</label>
        <textarea
          onChange={(event) =>
            props.handleChange("comments", event.target.value)
          }
          value={props.values.comments ? props.values.comments : ""}
        />
      </div>

      <div className={styles["radio-button-main"]}>
        <span>Would you use this trainer again?</span>
        <div className={styles["radio-button-container"]}>
          <RadioButton
            value={true}
            //   onChange={(event) => checkErrors(event)}
            handleChange={props.handleChange}
            values={props.values}
            stateToSet={"go-again"}
            label={"Yes"}
          />

          <RadioButton
            value={false}
            //   onChange={(event) => checkErrors(event)}
            handleChange={props.handleChange}
            values={props.values}
            stateToSet={"go-again"}
            label={"No"}
          />
        </div>
      </div>

      <div className={styles["submit-button-div"]}>
        <button disabled={!isDisabled || props.submitDisabled} onClick={submitReview}>
          Submit Review
        </button>
      </div>
    </div>
  );
}

export default ReviewForm;
