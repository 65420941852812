import styles from "./HowItWorks.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faComputerMouse, faPen } from "@fortawesome/free-solid-svg-icons";

function HowItWorks() {
  return (
    <div className={styles.container} id="how-it-works">
      <div className={styles.content}>
          <h1>How It Works</h1>
        <div>
          <h2><FontAwesomeIcon style={{width: '30px', marginRight: "5px"}} icon={faSearch} />Find</h2>
          <p>
            Use our advanced searching mechanisms to find the class or trainer
            you want to learn more about. If they are not available you can add
            them!
          </p>
        </div>

        <div>
          <h2><FontAwesomeIcon style={{width: '30px', marginRight: "5px"}} icon={faPen} />Review</h2>
          <p>
            Add a review using a simple review form to help the community learn
            more about your experience.
          </p>
        </div>

        <div>
          <h2><FontAwesomeIcon style={{width: '30px', marginRight: "5px"}} icon={faComputerMouse} />Interact!</h2>
          <p>
            Check out all the different classes and trainers at your disposal
            and change your workout regimen for the better. Use the like button
            if you feel a review reflects your feelings.
          </p>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
