import styles from "./Team.module.scss";

function Team() {
  document.title = "Trainer Rater | Team";

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>The Team</h1>
        <div className={styles.flex}>
          <div className={styles.bio}>
            <h2>Ryan Schwarz</h2>

            <p>
              Ryan, 24, is an NYU graduate who studied Computer Science.
              Currently a full stack software engineer professionally, Ryan has
              always had a passion for building out ad designing websites. Ryan
              enjoys working out in his free time and is an avid sports fan!
            </p>

            <p>
              A goal of Ryan's is to make the world a better place. What better
              way than to help create a platform that connects fitness
              enthusiasts.
            </p>
          </div>
          <div
            className={styles.image + " " + styles.image1}
            style={{ backgroundImage: "url(./ryanCover.jpeg)" }}
          />
        </div>

        <div className={styles.flex}>
          <div className={styles.bio}>
            <h2>Jack Eisenberg</h2>

            <p>
              Jack is studying to become a prosecutor at the Queens District
              Attorney’s Office.
            </p>

            <p>
              In the past 2 years, Jack lost over 50 pounds! His successful
              weight-loss journey was in part thanks to group fitness classes at
              places like Soul Cycle, Equinox, and Solid Core. Jack understands
              the value of group fitness— and instructors—to living a healthier
              lifestyle. He wants to help spread this lifestyle to others.
            </p>
          </div>
          <div
            className={styles.image + " " + styles.image2}
            style={{ backgroundImage: "url(./jackCover.jpeg)" }}
          />
        </div>
      </div>
    </div>
  );
}

export default Team;
