// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  deleteUser,
  reauthenticateWithCredential,
  EmailAuthProvider,
  signInWithRedirect,
  getRedirectResult,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: "select_account" });
const signInWithGoogle = async () => {
  try {
    await signInWithRedirect(auth, googleProvider);
    // After the page redirects back
    return true;
  } catch (err) {
    // return err;
    console.error(err);
    // alert(err.message);
  }
};

const confirmWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("id", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        id: user.uid,
        name: user.displayName,
        email: user.email,
        type: "user",
      });
    }
    return true;
  } catch (err) {
    // console.error(err);
    // alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  // console.log(email);
  // console.log(password);
  try {
    await signInWithEmailAndPassword(auth, email, password);
    return true;
    // console.log("HEY")
  } catch (err) {
    console.error(err);
    return err;
  }
};
const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      id: user.uid,
      name,
      email,
      type: "user",
    });
    return true;
  } catch (err) {
    if (err.code == "auth/weak-password") {
      return "Password must be over 6 characters";
    } else if (err.code == "auth/invalid-email") {
      return "Invalid email";
    } else if (err.code == "auth/email-already-in-use") {
      return "Account with this email already exists!";
    }
    // console.error(err);
    // alert(err.message);
    return err;
  }
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
    return true;
  } catch (err) {
    if (err.code == "auth/user-not-found") {
      return "No user found with the given email";
    }
    console.error(err.code);
    return err;
  }
};
const logout = () => {
  signOut(auth);
};

// 'file' comes from the Blob or File API
const fileUpload = async (file, fileName) => {
  const storageRef = ref(storage, fileName);
  uploadBytes(storageRef, file).then((snapshot) => {
    // console.log("Uploaded a blob or file!");
  });
};

const deleteAccount = async (password, userId) => {
  try {
    if (password != "") {
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        password
      );
      await reauthenticateWithCredential(auth.currentUser, credential);
    }
    deleteUser(auth.currentUser);
    deleteDoc(doc(db, "users", userId));
    return true;
  } catch (err) {
    return err;
  }
};
export {
  auth,
  db,
  storage,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  fileUpload,
  deleteAccount,
  confirmWithGoogle
};
