import Header from "./components/header/Header";
import AppRoutes from "./Routing";
import Footer from "./components/footer/Footer";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import styles from "./App.module.scss";
import { BrowserRouter as Router } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import React, { useContext, useEffect, useState } from "react";
import {
  AuthenticatedUserProvider,
  AuthenticatedUserContext,
} from "./providers/AuthenticatedUserProvider";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { db } from "./firebase";
import UserActionModal from "./components/user-actions/UserActionModal";

import { getRedirectResult } from "firebase/auth";

function App() {
  const { userInfo, setUserInfo } = useContext(AuthenticatedUserContext);

  const [user, loading, error] = useAuthState(auth);

  const [userActionOpen, setUserActionOpen] = useState(false);

  useEffect(() => {
    setInitializing(false);
    // const debugRedirectResult = async () => {
    //   // console.log("entering function")
    //   // console.log(user)
    //   const res = await getRedirectResult(auth);
    //   console.log(res)
    //   if (res) {
    //     // This is the signed-in user
    //     console.log(res)
    //     const user = res.user;
    //     const q = query(collection(db, "users"), where("id", "==", user.uid));
    //     const docs = await getDocs(q);
    //     if (docs.docs.length === 0) {
    //       console.log("Adding doc");
    //       await addDoc(collection(db, "users"), {
    //         id: user.uid,
    //         name: user.displayName,
    //         email: user.email,
    //         type: "user",
    //       }).then((data) => {
    //         setUserInfo({
    //           id: user.uid,
    //           name: user.displayName,
    //           email: user.email,
    //           type: "user",
    //           docId: data.id,
    //         })
    //         setInitializing(true);
    //       })
    //     }
    //     else{
    //       getData(user);
    //     }
    //   }
    //   else {
    //     getData(user);
    //   }
    // };

    if (loading) return;

    // debugRedirectResult();

    getData(user)
    
  }, [user, loading]);

  const [initializing, setInitializing] = useState(true);

  const getData = async (user) => {
    // setInitializing(true);
    if (user != null) {
      const usersRef = collection(db, "users");

      const q = query(usersRef, where("email", "==", user.email));

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((documentSnapshot) => {
        // console.log("User ID: ", documentSnapshot.id, documentSnapshot.data());
        setUserInfo({
          ...documentSnapshot.data(),
          docId: documentSnapshot.id,
        });
      });
    } else {
      setUserInfo(null);
    }
    setInitializing(true);
  };

  return (
    <div className="App">
      {!initializing && (
        <h1>
          <Bars
            height="80"
            width="80"
            color="#474747"
            ariaLabel="bars-loading"
            wrapperStyle={{ justifyContent: "center" }}
            wrapperClass="bars"
            visible={true}
          />
        </h1>
      )}
      {initializing && (
        <Router>
          <Header user={userInfo} setUserActionOpen={setUserActionOpen} />
          <div className={styles["main-div"]}>
            {userActionOpen && (
              <UserActionModal
                isOpen={userActionOpen}
                setIsOpen={setUserActionOpen}
              />
            )}
            <AppRoutes user={user} setUserActionOpen={setUserActionOpen} />
          </div>
          <Footer />
        </Router>
      )}
    </div>
  );
}

export default App;
