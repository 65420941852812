import styles from "./../log-in/Login.module.scss";
import { useState } from "react";
import {
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  signInWithGoogle
} from "./../../firebase";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

function Signup(props) {
  document.title = "Trainer Rater | Sign Up";

  const navigate = useNavigate();

  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");

  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  function handleChange(event, input) {
    // checkErrors(input, event.target.value)
    setValues((values) => {
      return {
        ...values,
        [input]: event.target.value,
      };
    });
  }

  function checkErrors(input, value) {
    if (input === "email" && value.length < 1) {
      setErrors((errors) => {
        return {
          ...errors,
          email:
            value.replace(/\s/g, "").length < 1
              ? "Cannot leave field blank!"
              : "",
        };
      });
    } else if (input === "email") {
      setErrors((errors) => {
        return {
          ...errors,
          email: validEmailRegex.test(value)
            ? ""
            : "Email is not a valid email!",
        };
      });
    } else if (input === "name") {
      setErrors((errors) => {
        return {
          ...errors,
          name:
            value.replace(/\s/g, "").length < 1
              ? "Cannot leave field blank!"
              : "",
        };
      });
    } else if (input === "password" && value.length < 6) {
      setErrors((errors) => {
        return {
          ...errors,
          password:
            value.replace(/\s/g, "").length < 6
              ? "Password must be at least 6 characters"
              : "",
        };
      });
    } else if (input === "confirmPassword" && value.length < 6) {
      setErrors((errors) => {
        return {
          ...errors,
          confirmPassword:
            value.replace(/\s/g, "").length < 6
              ? "Password must be at least 6 characters"
              : "",
        };
      });
    }

  }

  const isDisabled =
    values.name !== "" &&
    values.email !== "" &&
    values.password !== "" &&
    values.confirmPassword !== "" &&
    errors.name === "" &&
    errors.email === "" &&
    errors.password === "" &&
    errors.confirmPassword === ""
      ? false
      : true;

  return (
    <div className={styles["main-div"]}>
      {!props?.modal && <h2>Join Us</h2>}
      {!props?.modal && (
        <center>
          <p>
            Sign up to create reviews and engage with other users to create a
            positive uplifitng fitness community
          </p>
        </center>
      )}

      <div className={styles.email}>
        <input
          className={errors.name !== "" ? styles.error : undefined}
          onClick={() => {
            setErrors((errors) => {
              return {
                ...errors,
                name: "",
              };
            });
            setError("");
          }}
          placeholder="Enter Your Full Name"
          onChange={(event) => {
            handleChange(event, "name");
          }}
          onBlur={(event) => checkErrors("name", event.target.value)}
          type="text"
          value={values.name}
        />
      </div>

      <span className={styles["error-message"]}>
        {errors.name !== "" ? (
          <>
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faCircleExclamation}
            />
            {errors.name}
          </>
        ) : (
          ""
        )}
      </span>

      <div className={styles.email}>
        <input
          className={errors.email !== "" ? styles.error : undefined}
          onClick={() => {
            setErrors((errors) => {
              return {
                ...errors,
                email: "",
              };
            });
            setError("");
          }}
          placeholder="Enter Your Email"
          onChange={(event) => {
            handleChange(event, "email");
          }}
          onBlur={(event) => {
            checkErrors("email", event.target.value);
          }}
          type="text"
          value={values.email}
        />
      </div>

      <span className={styles["error-message"]}>
        {errors.email !== "" ? (
          <>
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faCircleExclamation}
            />
            {errors.email}
          </>
        ) : (
          ""
        )}
      </span>

      <div className={styles.password}>
        <input
          className={errors.password !== "" ? styles.error : undefined}
          onClick={() => {
            setErrors((errors) => {
              return {
                ...errors,
                password: "",
              };
            });
            setError("");
          }}
          placeholder="Enter Your Password"
          onChange={(event) => handleChange(event, "password")}
          onBlur={(event) => {
            checkErrors("password", event.target.value);
          }}
          type={!passwordShown ? "password" : "text"}
          value={values.password}
        />
        <FontAwesomeIcon
          className={styles.eye}
          onClick={togglePasswordVisiblity}
          icon={passwordShown ? faEyeSlash : faEye}
        />
      </div>

      <span className={styles["error-message"]}>
        {errors.password !== "" ? (
          <>
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faCircleExclamation}
            />
            {errors.password}
          </>
        ) : (
          ""
        )}
      </span>

      <div className={styles.password}>
        <input
          className={errors.confirmPassword !== "" ? styles.error : undefined}
          onClick={() => {
            setErrors((errors) => {
              return {
                ...errors,
                confirmPassword: "",
              };
            });
            setError("");
          }}
          placeholder="Confirm Your Password"
          onChange={(event) => handleChange(event, "confirmPassword")}
          type={!confirmPasswordShown ? "password" : "text"}
          value={values.confirmPassword}
          onBlur={(event) => {
            checkErrors("confirmPassword", event.target.value);
          }}
        />

        <FontAwesomeIcon
          className={styles.eye}
          onClick={toggleConfirmPasswordVisiblity}
          icon={confirmPasswordShown ? faEyeSlash : faEye}
        />
      </div>

      <span className={styles["error-message"]}>
        {errors.confirmPassword !== "" ? (
          <>
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faCircleExclamation}
            />
            {errors.confirmPassword}
          </>
        ) : (
          ""
        )}

        {error !== "" ? (
          <>
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faCircleExclamation}
            />
            {error}
          </>
        ) : (
          ""
        )}
      </span>

      <button
        disabled={isDisabled}
        onClick={() => {
          if (values.password !== values.confirmPassword) {
            setError("Passwords don't match");
          } else {
            registerWithEmailAndPassword(
              values.name,
              values.email,
              values.password
            )
              .then((data) => {
                if (data === true) {
                  logInWithEmailAndPassword(values.email, values.password).then(() => {
                    props.modal && props.setIsOpen(false);
                    !props?.modal && navigate("/");
                  });
                } else {
                  setError(data);
                }
              })
              .catch((err) => setError(err));
          }
        }}
        className={styles["login-btn"]}
      >
        Register
      </button>

      {/* <button
        className="gsi-material-button"
        onClick={() => {
          const logReturn = function () {
            return signInWithGoogle();
          };
          logReturn().then((data) => {
            // console.log(data.message);
            if (data === true) {
              // props.modal && props.setIsOpen(false);
              // !props?.modal && navigate("/");
            }
          });
        }}
      >
        <div className="gsi-material-button-state"></div>
        <div className="gsi-material-button-content-wrapper">
          <div className="gsi-material-button-icon">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              style={{ display: "block" }}
            >
              <path
                fill="#EA4335"
                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
              ></path>
              <path
                fill="#4285F4"
                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
              ></path>
              <path
                fill="#FBBC05"
                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
              ></path>
              <path
                fill="#34A853"
                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
              ></path>
              <path fill="none" d="M0 0h48v48H0z"></path>
            </svg>
          </div>
          <span className="gsi-material-button-contents">
            Sign up with Google
          </span>
          <span style={{ display: "none" }}>Sign in with Google</span>
        </div>
      </button> */}

      {/* <p>
        Have an account already?{" "}
        <a onClick={() => !props.modal ? navigate("/login") : props.setActive("Login")}>Sign in!</a>
      </p> */}
    </div>
  );
}

export default Signup;
