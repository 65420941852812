import styles from "./Announcements.module.scss";
import { announcementList } from "./AnnouncementData";
import Announcement from "./../../components/announcement/Announcement";
import { useNavigate } from "react-router-dom";

function Announcements(){
const navigate = useNavigate()
    return(
        <div className={styles.container}>
            <h1>Announcements</h1>
            {announcementList.map((item, index) => { 
                return (
                    <Announcement key={index} title={item.title} info={item.info} date={item.date} viewDetails={() => navigate("/announcements/announcement-1")}/>
                )
            })}
        </div>
    )

}

export default Announcements;