import { useState } from "react";
import Drawer from "@mui/material/Drawer";
import * as React from "react";
import styles from "./Filter.module.scss";

export default function FilterMenu(props) {
  const [anchor, setAnchor] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setAnchor(open);
  };

  return (
    <div key={"left"} className={styles["mobile-filter"]}>
      <button onClick={toggleDrawer(true)}>Filters</button>
      <Drawer
        PaperProps={{
          sx: {
            width: 350,
            background: "#EDF2F4",
            padding: "20px",
            boxSizing: "border-box"
          },
        }}
        
        anchor={"left"}
        open={anchor}
        onClose={toggleDrawer(false)}
        
      >
        <span className={styles.x} onClick={toggleDrawer(false)}>X</span>
        {props.children}
      </Drawer>
    </div>
  );
}
