import { db } from "../../firebase";
import {
  collection,
  getDoc,
  deleteDoc,
  doc,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import TrainerHeader from "./../../components/trainer/TrainerHeader";
import TrainerReview from "./../../components/trainer/TrainerReview";

import { useParams } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import styles from "./TrainerPage.module.scss";
import Dropdown from "./../../components/common/dropdown/Dropdown";

function TrainerProfile(props) {
  document.title = "Trainer Rater | Trainer Profile";

  const [data, setData] = useState(null);
  const [reviews, setReviews] = useState(null);

  const [loading, setLoading] = useState(true);
  const [isSelected, setIsSelected] = useState("Newest");

  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      const submission = (await getDoc(doc(db, "trainers", id))).data();
      setData(submission);
    };

    const getReviews = async () => {

      let users = []
      const usersData = await getDocs(collection(db, "users"));
      usersData.forEach((user) =>
        users.push({ ...user.data(), id: user.id })
      );

      const reviewsRef = collection(db, "reviews");

      const q = query(reviewsRef, where("trainer", "==", id));

      const querySnapshot = await getDocs(q);
      let reviewData = [];
      await querySnapshot.forEach((documentSnapshot) => {
        
            reviewData.push({
              ...documentSnapshot.data(),
              id: documentSnapshot.id,
              user: users.filter(
                (user) =>
                  user.email === documentSnapshot.data().user
              )[0] || { name: "Deleted User"},
            });
          });
          setReviews(reviewData.sort((a, b) => b.date - a.date));
  
      setLoading(false);
    };

    getData();
    getReviews();
  }, [id]);

  function handleSort(direction) {
    setIsSelected(direction);
    if (direction === "Oldest") {
      setReviews(reviews.sort((a, b) => a.date - b.date));
    } else if (direction === "Newest") {
      setReviews(reviews.sort((a, b) => b.date - a.date));
    } else if (direction === "Rating (Low - High)") {
      setReviews(reviews.sort((a, b) => a.rating - b.rating));
    } else if (direction === "Rating (High - Low)") {
      setReviews(reviews.sort((a, b) => b.rating - a.rating));
    }
  }

  async function confirmDelete(reviewID, review) {
    const q = query(
      collection(db, "classes"),
      where("class", "==", review.classname)
    );
    let classData = {};
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      classData = { data: doc.data(), id: doc.id };
    });

    classData.data.rating.splice(classData.data.rating.indexOf(review.rating), 1);
    classData.data.takeAgain.splice(classData.data.takeAgain.indexOf(review.again), 1);

    const ref = doc(db, "classes", classData.id);

    await updateDoc(ref, {
      rating: classData.data.rating,
      takeAgain: classData.data.takeAgain,
    });

    // function to delete post and remove it from list of reviews

    data.rating.splice(data.rating.indexOf(review.rating), 1);
    data.takeAgain.splice(data.takeAgain.indexOf(review.again), 1);

    const ref2 = doc(db, "trainers", id);

    await updateDoc(ref2, {
      rating: data.rating,
      takeAgain: data.takeAgain,
    }).then(
      setData({
        ...data,
        rating: data.rating,
        takeAgain: data.takeAgain,
      })
    );

    await deleteDoc(doc(db, "reviews", reviewID)).then(() =>
      setReviews(reviews.filter((review) => review.id !== reviewID))
    );
  }

  if (loading) {
    return (
      <div className={styles["trainer-profile-container"]}>
        <br />
        <center>
          <Bars
            height="80"
            width="80"
            color="#2B2D42"
            ariaLabel="bars-loading"
            wrapperStyle={{
              display: "block !important)",
            }}
            wrapperClass="bars"
            visible={true}
          />
        </center>
      </div>
    );
  } else {
    return (
      <div className={styles["trainer-profile-container"]}>
        <div className={styles["trainer-profile-flex"]}>
          {!loading && data && reviews && (
            <div className={styles.header}>
              <TrainerHeader reviews={reviews} data={data} id={id} />
            </div>
          )}
          <div className={styles.reviews}>
            <div className={styles["dropdown-container"]}>
              <Dropdown
                selected={isSelected}
                setIsSelected={setIsSelected}
                handleChange={handleSort}
                options={[
                  "Oldest",
                  "Newest",
                  "Rating (High - Low)",
                  "Rating (Low - High)",
                ]}
                page={"trainer"}
              />
            </div>
            <div className={styles["reviews-scroll"]}>
              {!loading &&
                data &&
                reviews &&
                reviews.length > 0 &&
                reviews.map((review, index) => (
                  <TrainerReview
                    key={index}
                    review={review}
                    trainerId={id}
                    setIsOpen={props.setIsOpen}
                    confirmDelete={confirmDelete}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TrainerProfile;
