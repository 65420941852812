import { useHits } from "react-instantsearch";
import { useNavigate } from "react-router-dom";
import styles from "./SearchPage.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDumbbell } from "@fortawesome/free-solid-svg-icons";

function ClassesHit({ hit }) {
  function check(a, b) {
    return a + b;
  }

  const sum = hit.rating.reduce(check);
  const avg = sum / hit.rating.length
  const stars = [0, 0, 0, 0, 0].map((item, index) => {
    if (avg / (index + 1) >= 1) {
      return 1;
    } else if (
      avg / (index + 1) < 1 &&
      avg % (index + 1 > 0) &&
      index + 1 - avg < 1
    ) {
      return avg % (index + 1 > 0);
    } else {
      return 0;
    }
  });

  const navigate = useNavigate();
  return (
    <div
      className={styles["trainer-item"]}
      onClick={() => navigate("../classes/" + hit.class)}
    >
      <div className={styles.top}>
        {/* <div><span className={styles.name}>{props.review.classname} at {props.review.type}</span></div> */}
        <h2>{hit.class}</h2>
 
        <div className={styles["stars-mobile"]}>
          <span className={styles.rating}>{hit.rating != 0 ? avg.toFixed(2) : "New"}</span>
          <span className={styles.star}>
          {hit.rating != 0 && <FontAwesomeIcon icon={faDumbbell} />}
          </span>
          <span style={{ marginLeft: "5px" }}>({hit.rating.length})</span>
        </div>

      <div className={styles.content}>
        <span>{hit.gym}</span>
      </div>
    </div>
    </div>

  );
}

function CustomHits(props) {

  const { hits, results, sendEvent } = useHits(props);
  return <>{hits.map(hit => <ClassesHit hit={hit}/>)}</>;
}

export default ClassesHit;
