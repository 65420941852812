import Announcements from "../../pages/announcements/Announcements";
import styles from "./Announcement.module.scss";

function Announcement(props) {
  return (
    <div className={styles.container}>
      <div className={styles.flex}>
        <h2>{props.title}</h2>
        <span>{new Date(props.date).toDateString()}</span>
      </div>
      <p>{props.info}</p>
      <span className={styles.btn} onClick={props.viewDetails}>More Details</span>
    </div>
  );
}

export default Announcement;
