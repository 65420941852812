import styles from "./GymHeader.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDumbbell } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function GymHeader(props) {
  const navigate = useNavigate();

  function check(a, b) {
    return a + b;
  }

  function calcStars(ratingArray) {
    // // Reducing the numbers of the array
    let sum;
    if (ratingArray.length > 0) {
      sum = ratingArray.reduce(check);
    } else {
      sum = 0;
    }

    // // Calculating the average of the numbers
    var avg = sum / ratingArray.length;

    const stars = [0, 0, 0, 0, 0].map((item, index) => {
      if (avg / (index + 1) >= 1) {
        return 1;
      } else if (
        avg / (index + 1) < 1 &&
        avg % (index + 1 > 0) &&
        index + 1 - avg < 1
      ) {
        return avg % (index + 1 > 0);
      } else {
        return 0;
      }
    });

    return stars;
  }

  return (
    <div className={styles.container}>
      <div className={styles.flex}>
        <div className={styles.left}>
          <h1 className={styles.name}>{props.gymname}</h1>
        </div>

        <div className={styles.right}>
          <span
            className={styles["add-review-button"]}
            onClick={() => navigate("/add-review?class=" + props?.data?.id)}
          >
            Review {props.gymname}
          </span>
          {props.data.map((item, index) => (
            <div className={styles["flex-row"]} key={index}>
              <span onClick={() => navigate("./../../classes/" + item.class)}>{item.class}</span>
              <div className={styles["stars"]}>
                {calcStars(item.rating).map((star, index) => (
                  <div key={index} className={styles["star"]}>
                    <span className={styles["star-under"]}>
                      <FontAwesomeIcon icon={faDumbbell} />
                    </span>
                    <span
                      style={{ width: star * 100 + "%" }}
                      className={styles["star-over"]}
                    >
                      <FontAwesomeIcon icon={faDumbbell} />
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}

          {/* <span className={styles.reviews}>
            {avg.toFixed(2)}/5<span style={{ margin: "0 5px" }}>•</span>
            {Math.round(again)}% would take again
            <span className={styles.again}>
              {" "}
              • {props.data.rating.length} Reviews
            </span>
          </span> */}
        </div>
      </div>
    </div>
  );
}

export default GymHeader;
