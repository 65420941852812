import styles from "./Account.module.scss";
import { useContext, useState, useEffect } from "react";
import { AuthenticatedUserContext } from "./../../providers/AuthenticatedUserProvider";
import { db, logout } from "../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import TrainerReview from "./../../components/trainer/TrainerReview";
import { useNavigate } from "react-router-dom";
import NotSignedIn from "../../components/account/NotSignedIn";
import ForgotPassword from "../../pages/forgot/ForgotPassword";
import Delete from "./../delete/Delete";
import ModalBase from "./../../components/common/modal/Modal";
import { Bars } from "react-loader-spinner";

function Account(props) {
  document.title = "Trainer Rater | Account";

  const navigate = useNavigate();
  const { userInfo, setUserInfo } = useContext(AuthenticatedUserContext);

  const [reviews, setReviews] = useState(null);
  // const [trainers, setTrainers] = useState(null);

  const [likedToRemove, setLikedToRemove] = useState([]);
  const [likedToAdd, setLikedToAdd] = useState([]);

  const [liked, setLiked] = useState(null);

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Reviews");
  const [isForgotOpen, setForgotOpen] = useState(false);
  const [isDeleteOpen, setDeleteOpen] = useState(false);

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      let trainersArray = [];
      const trainersData = await getDocs(collection(db, "trainers"));
      trainersData.forEach((trainer) =>
        trainersArray.push({ ...trainer.data(), id: trainer.id })
      );
      // setTrainers(trainersArray)

      const getReviews = async () => {
        if (userInfo) {
          const reviewsRef = collection(db, "reviews");

          const q = query(reviewsRef, where("user", "==", userInfo.email));

          const querySnapshot = await getDocs(q);
          let reviewData = [];
          await querySnapshot.forEach((documentSnapshot) => {
            const q = query(
              collection(db, "users"),
              where("email", "==", documentSnapshot.data().user)
            );

            getDocs(q).then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                reviewData.push({
                  ...documentSnapshot.data(),
                  id: documentSnapshot.id,
                  user: { ...doc.data() } || { name: "Deleted User" },
                  trainer: trainersArray.filter(
                    (trainer) => trainer.id === documentSnapshot.data().trainer
                  )[0],
                });
              });
              setReviews(reviewData.sort((a, b) => b.date - a.date));
            });
          });
        }
      };

      const getLiked = async () => {
        if (userInfo) {
          const reviewsRef = collection(db, "reviews");

          const q = query(
            reviewsRef,
            where("likes", "array-contains", userInfo.email)
          );

          const querySnapshot = await getDocs(q);
          let reviewData = [];
          await querySnapshot.forEach((documentSnapshot) => {
            const q = query(
              collection(db, "users"),
              where("email", "==", documentSnapshot.data().email)
            );

            getDocs(q).then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                reviewData.push({
                  ...documentSnapshot.data(),
                  id: documentSnapshot.id,
                  user: { ...doc.data() } || { name: "Deleted User" },
                  trainer: trainersArray.filter(
                    (trainer) => trainer.id === documentSnapshot.data().trainer
                  )[0],
                });
              });
              setLiked(reviewData.sort((a, b) => b.date - a.date));
            });
          });
        }
      };
      getReviews();
      getLiked();
      setLoading(false);
    };
    getData();
  }, []);

  function updateLikedPostsAfterToggle(param) {
    param === "liked" &&
      setLiked(
        liked?.filter((item) => likedToRemove?.includes(item.id) === false)
      );
  }

  async function confirmDelete(reviewID, review) {
    const q = query(
      collection(db, "classes"),
      where("class", "==", review.classname)
    );
    let classData = {};
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      classData = { data: doc.data(), id: doc.id };
    });

    classData.data.rating.splice(
      classData.data.rating.indexOf(review.rating),
      1
    );
    classData.data.takeAgain.splice(
      classData.data.takeAgain.indexOf(review.again),
      1
    );

    const ref = doc(db, "classes", classData.id);

    await updateDoc(ref, {
      rating: classData.data.rating,
      takeAgain: classData.data.takeAgain,
    });

    // function to delete post and remove it from list of reviews
    review.trainer.rating.splice(
      review.trainer.rating.indexOf(review.rating),
      1
    );
    review.trainer.takeAgain.splice(
      review.trainer.takeAgain.indexOf(review.again),
      1
    );

    const ref2 = doc(db, "trainers", review.trainer.id);

    await updateDoc(ref2, {
      rating: review.trainer.rating,
      takeAgain: review.trainer.takeAgain,
    });

    await deleteDoc(doc(db, "reviews", reviewID)).then(() => {
      setReviews(reviews.filter((review) => review.id !== reviewID));
      setLiked(liked.filter((review) => review.id !== reviewID));
    });
  }

  if (loading) {
    return (
      <div className={styles["trainer-profile-container"]}>
        <br />
        <center>
          <Bars
            height="80"
            width="80"
            color="#2B2D42"
            ariaLabel="bars-loading"
            wrapperStyle={{
              display: "block !important)",
            }}
            wrapperClass="bars"
            visible={true}
          />
        </center>
      </div>
    );
  } else if (userInfo != null && !loading) {
    return (
      <div className={styles["account-main"]}>
        <ModalBase isOpen={isForgotOpen} close={() => setForgotOpen(false)}>
          <ForgotPassword
            modal={true}
            setIsOpen={setForgotOpen}
            email={userInfo.email}
            account={true}
          />
        </ModalBase>
        <ModalBase isOpen={isDeleteOpen} close={() => setDeleteOpen(false)}>
          <Delete modal={true} setIsOpen={setDeleteOpen} userInfo={userInfo}/>
        </ModalBase>
        <div className={styles.top}>
          <h2>Hi, {userInfo.name.split(" ")[0]}!</h2>
          <div>
            <span onClick={() => {
              logout()
            setUserInfo(null)}
              }>Logout</span>
            <span onClick={() => setForgotOpen(true)}>Reset Password</span>
            <span onClick={() => setDeleteOpen(true)}>Delete Account</span>
          </div>
        </div>
        <div className={styles["tabs-bar"]}>
          {/* <span
            className={activeTab == "Account" ? styles.active : ""}
            onClick={() => setActiveTab("Account")}
          >
            Account
          </span> */}
          <span
            className={activeTab == "Reviews" ? styles.active : ""}
            onClick={() => {
              updateLikedPostsAfterToggle("reviews");
              setActiveTab("Reviews");
            }}
          >
            Reviews
          </span>
          <span
            className={activeTab == "Liked" ? styles.active : ""}
            onClick={() => {
              updateLikedPostsAfterToggle("liked");
              setActiveTab("Liked");
            }}
          >
            Liked
          </span>
        </div>

        {activeTab == "Reviews" && (
          <div className={styles["content-main"]}>
            <div className={styles.reviews}>
              {!loading &&
                reviews &&
                reviews.length > 0 &&
                reviews.map((review, index) => (
                  <TrainerReview
                    key={index}
                    review={review}
                    likedToRemove={likedToRemove}
                    setLikedToAdd={setLikedToAdd}
                    liked={liked}
                    setLiked={setLiked}
                    account={"review"}
                    confirmDelete={confirmDelete}
                  />
                ))}
            </div>
          </div>
        )}

        {activeTab == "Liked" && (
          <div className={styles["content-main"]}>
            <div className={styles.reviews}>
              {!loading &&
                liked &&
                liked.length > 0 &&
                liked.map((review, index) => (
                  <TrainerReview
                    key={index}
                    review={review}
                    likedToRemove={likedToRemove}
                    setLikedToRemove={setLikedToRemove}
                    account={"liked"}
                    confirmDelete={confirmDelete}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className={styles["account-main"]}>
        <NotSignedIn />
      </div>
    );
  }
}

export default Account;
