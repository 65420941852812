import { useState, useEffect, useRef } from "react";
import "./Dropdown.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";


function Dropdown(props) {
  const [isActive, setIsActive] = useState(false);
  const handleChange = props.handleChange;
  const selected = props.selected;
  const setIsSelected = props.setIsSelected;

  const node = useRef();

  const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
      };
    }, [ref, handler]);
  };

  useOnClickOutside(node, () => setIsActive(false));

  return (
    <div className={"dropdown" + " " + props?.page} ref={node}>
      <div
        onClick={(e) => {
          setIsActive(!isActive);
        }}
        className={"dropdown-btn" + " " + props?.page}
      >
        <div>{selected}</div>
        <div>
          <FontAwesomeIcon icon={isActive ? faCaretUp : faCaretDown} />
        </div>
      </div>
      <div
        className={"dropdown-content"}
        style={{ display: isActive ? "block" : "none" }}
      >
        {props.options.map((option, index) => (
          <div
            className="item"
            key={index}
            onClick={(e) => {
              
              handleChange(e.target.textContent, props.team);
              setIsSelected(e.target.textContent);
              setIsActive(!isActive);
            }}
          >
            {option}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Dropdown;
